import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";

import { TOUR_Z_INDICES } from "../TourRenderer";
import { useDomObserver } from "../utils/useDomObserver";

type ArrowConfig = {
  path: string; // SVG path for the arrow
  width: number;
  height: number;
  offset: [number, number]; // An offset for popper to use to ensure that the arrow is correctly positioned.
};
export type ArrowPlacement = "right-start" | "right-end";

const arrowConfig: Record<ArrowPlacement, ArrowConfig> = {
  "right-start": {
    path: "M14.6953 15.833C16.1502 16.5631 17.2662 15.9514 18.4022 15.9317C19.1794 15.9119 20.0164 15.9317 20.7139 16.1882C22.2086 16.7407 22.4478 18.8718 21.1524 19.7204C20.4947 20.1347 19.7374 20.4702 18.9801 20.6478C14.217 21.7528 9.43401 22.8381 4.63107 23.8642C3.75419 24.0615 2.77766 24.0221 1.86092 23.884C0.465874 23.6866 -0.35123 22.1672 0.147 20.9043C0.286504 20.5491 0.485799 20.1939 0.685091 19.8585C2.67801 16.6026 4.63108 13.327 6.68379 10.1105C7.16209 9.36069 7.81975 8.65031 8.55713 8.11753C9.9721 7.09143 11.4269 7.76234 11.7259 9.51855C11.8056 10.0119 11.7458 10.5446 11.7458 11.3932C12.543 11.0972 13.101 10.9393 13.6391 10.6631C17.0669 8.92658 20.4748 7.1901 23.8827 5.43388C31.4757 1.54654 39.6865 0.224448 48.1166 0.00738857C50.7472 -0.0518096 53.4576 0.244176 55.9886 0.915089C59.9545 1.96092 63.8407 3.32247 67.6472 4.80242C71.932 6.4797 74.7619 9.7356 76.5755 13.8795C76.8744 14.5701 77.0737 15.4186 76.9741 16.1487C76.8944 16.6815 76.3563 17.3919 75.858 17.5695C75.3598 17.7471 74.4829 17.5103 74.1043 17.1156C73.4665 16.5039 73.0281 15.6357 72.5697 14.8464C70.6765 11.6497 68.0059 9.43961 64.4585 8.25565C60.8115 7.05195 57.2641 5.53255 53.4178 5.03923C44.9279 3.97366 36.737 5.23654 28.9049 8.4727C24.64 10.2289 20.5944 12.5377 16.4491 14.6096C15.8712 14.9056 15.3928 15.3397 14.6953 15.833Z",
    width: 77,
    height: 24,
    offset: [-30, 0],
  },
  "right-end": {
    path: "M14.6953 8.16698C16.1502 7.43687 17.2662 8.04859 18.4022 8.06832C19.1794 8.08805 20.0164 8.06831 20.7139 7.81179C22.2086 7.25927 22.4478 5.12816 21.1524 4.27965C20.4947 3.86526 19.7374 3.52979 18.9801 3.3522C14.217 2.24717 9.43401 1.16188 4.63107 0.13578C3.75419 -0.0615471 2.77766 -0.022101 1.86092 0.116028C0.465874 0.313355 -0.351227 1.83279 0.147003 3.09569C0.286507 3.45088 0.485802 3.80605 0.685089 4.14151C2.67801 7.3974 4.63108 10.673 6.68378 13.8895C7.16209 14.6393 7.81975 15.3497 8.55713 15.8825C9.9721 16.9086 11.4269 16.2377 11.7259 14.4815C11.8056 13.9881 11.7458 13.4554 11.7458 12.6068C12.543 12.9028 13.101 13.0607 13.6391 13.3369C17.0669 15.0734 20.4748 16.8099 23.8827 18.5661C31.4757 22.4535 39.6865 23.7756 48.1166 23.9926C50.7472 24.0518 53.4576 23.7558 55.9886 23.0849C59.9545 22.0391 63.8407 20.6775 67.6472 19.1976C71.932 17.5203 74.7619 14.2644 76.5755 10.1205C76.8744 9.42988 77.0737 8.58138 76.9741 7.85127C76.8944 7.31848 76.3563 6.60809 75.858 6.4305C75.3598 6.2529 74.4829 6.4897 74.1043 6.88436C73.4665 7.49607 73.0281 8.36431 72.5697 9.15362C70.6765 12.3503 68.0059 14.5604 64.4585 15.7444C60.8115 16.948 57.2641 18.4675 53.4178 18.9608C44.9279 20.0263 36.737 18.7635 28.9049 15.5273C24.64 13.7711 20.5944 11.4623 16.4491 9.3904C15.8712 9.09441 15.3928 8.6603 14.6953 8.16698Z",
    width: 77,
    height: 24,
    offset: [30, 0],
  },
};

type ArrowProps = {
  selector: string;
  placement: ArrowPlacement;
  offset?: [number, number];
};

/**
 * Renders an arrow pointing at a given element.
 */
export function Arrow({ selector, placement, offset: rawOffset }: ArrowProps) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  const { width, height, path, offset: arrowOffset } = arrowConfig[placement];
  const offset: [number, number] = [
    (rawOffset?.[0] ?? 0) + (arrowOffset?.[0] ?? 0),
    (rawOffset?.[1] ?? 0) + (arrowOffset?.[1] ?? 0),
  ];

  const target = useDomObserver(selector);

  const { styles, attributes, update } = usePopper(
    targetElement,
    popperElement,
    {
      modifiers: [
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ],
      placement,
    }
  );

  useEffect(() => {
    update?.();
  }, [target, update]);

  if (!target) {
    return null;
  }

  // TODO: we should read out the placement that popper _actually_ uses (may be different to what we told it to use)
  //  and the arrow path based on that. This would allow us to ensure arrows are responsive. Would require us
  // to ensure ArrowPlacement = popper.Placement (currently a subset supported).
  return (
    <>
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          zIndex: TOUR_Z_INDICES.CONTENT,
        }}
        className="text-red-500"
        {...attributes.popper}
      >
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path} />
        </svg>
      </div>
      {/* We support "virtual" elements (e.g. elements that exist in an iframe) so we need to render a fake version for popper to use. */}
      <div
        ref={setTargetElement}
        className="pointer-events-none block fixed"
        style={{
          left: target.rect.x,
          top: target.rect.y,
          width: target.rect.width,
          height: target.rect.height,
        }}
      />
    </>
  );
}
