import { useCallback, useLayoutEffect, useState } from "react";

export type ScreenBreakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

export const breakpointForWidth = (width: number): ScreenBreakpoint => {
  if (width < 480) {
    return "xs";
  }
  if (width < 768) {
    return "sm";
  }
  if (width < 1024) {
    return "md";
  }
  if (width < 1440) {
    return "lg";
  }
  return "xl";
};

type ResponsiveValue<T> = {
  // xs is going to serve as the default value for all screen sizes
  xs: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
};

export function useResponsiveValue<T = unknown>(
  responsiveValue: ResponsiveValue<T>
): T {
  const { xs, sm, md, lg, xl } = responsiveValue;
  const [value, setValue] = useState<T>(xs);

  const onResize = useCallback(() => {
    const breakpoint = breakpointForWidth(window.innerWidth);
    switch (breakpoint) {
      case "xs":
        setValue(xs);
        break;
      case "sm":
        setValue(sm ?? xs);
        break;
      case "md":
        setValue(md ?? sm ?? xs);
        break;
      case "lg":
        setValue(lg ?? md ?? sm ?? xs);
        break;
      case "xl":
        setValue(xl ?? lg ?? md ?? sm ?? xs);
        break;
      default:
        setValue(xs);
        break;
    }
  }, [xs, sm, md, lg, xl]);

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);
  return value;
}
