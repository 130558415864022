import { z } from "zod";

export const ActivityVerbSchema = z.enum([
  "GuideMovedStage",
  "GuideChangedStatus",
  "GuideOverviewContentUpdated",
  "GuideBannerUpdated",
  "GuideInterviewPlanChanged",
  "GuideInterviewPlanOverridesCleared",
  "GuideViewed",

  "GuidePostCreated",
  "GuidePostDeleted",
  "GuidePostEdited",
  "GuidePostMarkedRead",
  "GuidePostReplyCreated",

  "ActionCreated",
  "ActionCompleted",
  "ActionDismissed",
  "ActionEnqueued",
  "ActionUpdated",
  "ActionMovedStage",
  "ActionChangedStatus",

  "SchedulingRequestDrafted",
  "SchedulingRequestCreated",
  "SchedulingRequestUpdated",
  "SchedulingRequestAvailabilitySent",
  "SchedulingRequestAdditionalAvailabilitySent",
  "SchedulingRequestAvailabilityReceived",
  "SchedulingRequestAvailabilityManuallyAdded",
  "SchedulingRequestAvailabilityManuallyUpdated",
  "SchedulingRequestConfirmationSent",
  "SchedulingRequestRescheduleConfirmationSent",
  "SchedulingRequestCompleted",
  "SchedulingRequestArchived",
  "SchedulingRequestAssigned",
  "SchedulingRequestAssignedToQueue",
  "SchedulingRequestReopened",
  "SchedulingRequestUnarchived",
  "SchedulingRequestPriorityChanged",

  "InterviewScheduled",
  "InterviewRescheduled",
  "InterviewInterviewerChanged",
  "InterviewUpdated",
  "InterviewConfirmed",
  "InterviewCancelled",
  "InterviewRestored",
  "InterviewCompleted",

  "InterviewNeedsReschedule",
  "InterviewCompletedForInterviewer",
  "InterviewerRemoved",
  "InterviewerInvited",
  "InterviewerRsvp",
  "InterviewerNoShowed",

  "InterviewerPoolCreated",
  "InterviewerPoolSettingsUpdated",
  "InterviewerPoolQualifiedUserAdded",
  "InterviewerPoolTrainingUserAdded",
  "InterviewerPoolTrainingUserMovedStage",
  "InterviewerPoolTrainingUserMarkedAsQualified",
  "InterviewerPoolUserRemoved",
  "InterviewerPoolTrainingUserCompletedShadow",
  "InterviewerPoolTrainingUserCompletedReverseShadow",

  "AvailabilityRequestCreated",
  "AvailabilityRequestUpdated",
  "AvailabilityRequestCompleted",

  "AvailabilitySubmissionCreated",
  "AvailabilitySubmissionUpdated",
  "AvailabilitySubmissionCleared",

  "SelfScheduleRequestCreated",
  "SelfScheduleRequestUpdated",
  "SelfScheduleRequestCompleted",
  "SelfScheduleRequestCancelled",

  "SelfScheduleSubmissionCreated",
  "SelfScheduleSubmissionUpdated",

  "BookingLinkCreated",
  "BookingLinkUpdated",
  "BookingLinkArchived",

  "BookingLinkSubmissionCreated",
  "BookingLinkSubmissionUpdated",

  "AtssyncApplicationMovedStage",

  "CommentCreated",

  "MessageBounced",

  "ChatMessagePosted",

  "AutomationCreated",
  "AutomationUpdated",
  "AutomationArchived",

  "AutomatedActionCreated",
  "AutomatedActionUpdated",
  "AutomatedActionArchived",
]);

export type ActivityVerb = z.infer<typeof ActivityVerbSchema>;

export const ActivityVerb = ActivityVerbSchema.enum;
