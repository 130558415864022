import { NotificationForDisplayFragment } from "generated/graphql-codegen/graphql";
import { ActivityVerb } from "shared/activity/types";

import { NotificationType } from "./types";

export function mapNotificationFragmentToNotification({
  id,
  activity,
  readAt,
  eventTime,
}: NotificationForDisplayFragment): NotificationType {
  if (!activity) {
    throw new Error("Notification activity is required");
  }

  const { verb, meta, href, actor, chatMessage } = activity;

  return {
    id,
    verb: verb as ActivityVerb,
    meta,
    read: !!readAt,
    href,
    createdAt: eventTime,
    actor: actor
      ? {
          id: actor.id,
          name: actor.name,
          avatarUrl: actor.imageUrl || undefined,
        }
      : undefined,
    chatMessage: chatMessage
      ? {
          id: chatMessage.id,
          automatedAction: chatMessage.automatedAction
            ? {
                id: chatMessage.automatedAction.id,
                requireReview: chatMessage.automatedAction.requireReview,
                scheduledAt: chatMessage.automatedAction.scheduledAt,
              }
            : undefined,
        }
      : undefined,
  };
}
