import * as Ariakit from "@ariakit/react";
import { atlasHelp } from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Menu } from "@resource/atlas/menu-v2";
import { useLogEvent } from "analytics";
import { V2_HELP_CENTER_PAGE } from "utils/constants/href";
import useIntercom from "utils/useIntercom";

function AppNavHelpMenu() {
  const { callIntercom } = useIntercom();
  const logEvent = useLogEvent({ component: "HelpMenu" });
  const store = Ariakit.useMenuStore({
    placement: "right-start",
  });
  const state = Ariakit.useStoreState(store);

  return (
    <Menu.Root store={store}>
      <Ariakit.MenuButton>
        <ListItem
          isInteractive
          isSelected={state.open}
          icon={atlasHelp}
          isSubtleIcon
        >
          Support
        </ListItem>
      </Ariakit.MenuButton>
      <Menu.Menu portal>
        <Menu.Item
          onClick={() => {
            logEvent("Help Menu Contact Support Clicked");
            callIntercom("show");
          }}
        >
          Contact support
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            logEvent("Account Menu Help Center Clicked");
            window.open(V2_HELP_CENTER_PAGE, "_blank");
          }}
        >
          Help center
        </Menu.Item>
      </Menu.Menu>
    </Menu.Root>
  );
}

export default AppNavHelpMenu;
