import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  getMainDefinition,
  offsetLimitPagination as defaultOffsetLimitPagination,
} from "@apollo/client/utilities";
import { formatGraphQLErrorMessage } from "client/utils/apollo";
import merge from "deepmerge";
import {
  ClientOptions,
  ConnectionInitMessage,
  createClient as gqlWsCreateClient,
  MessageType,
  stringifyMessage,
} from "graphql-ws";
import _, { uniqWith } from "lodash";
import isEqual from "lodash/isEqual";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
  ORGANIZATION_ID_COOKIE_NAME,
} from "shared/constants/auth";
import { captureSentryError } from "shared/errors/sentryError";
import authRedirectApolloLink from "utils/authRedirectApolloLink";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
const PERSISTED_CACHE_KEY = "GUIDE_apollo-cache-persist";

export const clearPersistedCache = () => {
  const { localStorage } = window;
  // Set the key in localStorage
  localStorage.setItem(PERSISTED_CACHE_KEY, "");
};

// Our AWS api gateway doesn't seem to want to respect protocol; we know our socket
// handles the protocol so we can just ignore it.
class WebSocketNoProtocol extends WebSocket {
  // This is not useless. Ensures protocol arg does not get passed on.
  // eslint-disable-next-line no-useless-constructor
  constructor(url: string) {
    super(url);
  }
}

const createClient = <
  P extends ConnectionInitMessage["payload"] = ConnectionInitMessage["payload"]
>(
  options: ClientOptions<P>
) => {
  return gqlWsCreateClient({
    ...options,
    webSocketImpl: WebSocketNoProtocol,
    on: {
      connected: (socket, ...args) => {
        options.on?.connected?.(socket, ...args);

        const sendHeartbeat = async () => {
          const ws = socket as WebSocket;
          if (ws.readyState === WebSocket.OPEN) {
            let token: string | undefined;
            try {
              const tokenRequest = await fetch("/api/auth/token");
              token = (await tokenRequest.json())?.token;
            } catch (err) {
              console.error("Failed to fetch auth0 token", { err });
            }

            await ws.send(
              stringifyMessage({
                type: MessageType.Ping,
                payload: {
                  token: token ?? undefined,
                  currentOrganizationId: await getCookie(
                    ORGANIZATION_ID_COOKIE_NAME
                  ),
                  impersonationMembershipId: await getCookie(
                    IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME
                  ),
                },
              })
            );

            // Send again in 8 minutes
            setTimeout(sendHeartbeat, 8 * 60 * 1000);
          }
        };

        sendHeartbeat();
      },
    },
  });
};

function createSentryErrorLink() {
  return onError((errorResponse) => {
    captureSentryError(
      new ApolloError({
        errorMessage: formatGraphQLErrorMessage(errorResponse),
        graphQLErrors: errorResponse.graphQLErrors,
        networkError: errorResponse.networkError,
        extraInfo: {
          operationName: errorResponse.operation.operationName,
          variables: errorResponse.operation.variables,
        },
      })
    );
  });
}

// TODO: we may want to be isomorphic again here to support server side.
function createIsomorphLink() {
  const httpLink = new HttpLink({
    uri: "/api/graphql",
    credentials: "same-origin",
  });
  return ApolloLink.from([
    authRedirectApolloLink(async (errorCode?: string) => {
      if (!window.location.pathname.startsWith("/login")) {
        window.location.href = `${window.location.origin}/login${
          errorCode ? `?error_code=${errorCode}` : ""
        }`;
      }
    }),
    httpLink,
  ]);
}

async function getCookie(name: string) {
  if (window?.cookieStore) {
    try {
      const cookie = await window.cookieStore.get(name);
      return cookie.value;
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
  return undefined;
}

type GuideApolloClient = ApolloClient<Record<string, unknown>>;

let apolloClient: GuideApolloClient;

const defaultPaginationRead: FieldReadFunction = (
  existing,
  { args, variables }
) => {
  const { offset = 0, limit = existing?.length } = args ?? variables ?? {};

  return (
    existing &&
    existing.slice(offset, _.isNull(limit) ? existing?.length : offset + limit)
  );
};

async function createApolloClient() {
  const cache = new InMemoryCache({
    possibleTypes: {
      FieldChanges: [
        "StringFieldChange",
        "BooleanFieldChange",
        "StringArrayFieldChange",
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          guide: defaultOffsetLimitPagination(["order_by"]),
          teammates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          personalProfileFindUnique: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "Interviewer",
                id: args?.where.id,
              });
            },
          },
          guidePostById: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "GuidePost",
                id: args?.id,
              });
            },
          },
          guideById: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "Guide",
                id: args?.guideId,
              });
            },
          },
        },
      },
      Recipient: {
        keyFields: ["id", "email"],
      },
      ConferenceRoomEvent: {
        keyFields: ["startTime", "endTime"],
      },
      Organization: {
        fields: {
          guides: {
            ...defaultOffsetLimitPagination(["orderBy", "where"]),
            read: defaultPaginationRead,
          },
          jobs: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          guideTemplatesView: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          inboxMessages: {
            ...defaultOffsetLimitPagination([]),
            read: defaultPaginationRead,
          },
          paginatedPostTemplates: {
            ...defaultOffsetLimitPagination(["orderBy", "where"]),
            read: defaultPaginationRead,
          },
          postTemplates: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          actions: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          interviewerPoolById: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "InterviewerPool",
                id: args?.id,
              });
            },
          },
        },
      },
      ScheduleInterviewAction: {
        merge(existing, incoming, { readField }) {
          if (!existing) {
            return incoming;
          }
          const existingVersion = readField("version", existing);
          const incomingVersion = readField("version", incoming);

          if (!existingVersion || !incomingVersion) {
            return incoming;
          }
          return existingVersion > incomingVersion ? existing : incoming;
        },
      },
      SentEmail: {
        keyFields: ["sendgridId"],
      },
      Guide: {
        fields: {
          activity: {
            keyArgs: ["guideId"],
            merge(existing, incoming) {
              return [...(existing ?? []), ...incoming];
            },
          },
        },
      },
      SchedulingRequest: {
        fields: {
          algorithmSuggestedScenarios: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          activity: {
            keyArgs: ["schedulingRequestId"],
            merge(existing, incoming) {
              return [...(existing ?? []), ...incoming];
            },
          },
        },
      },
      ScheduledInterview: {
        fields: {
          activity: {
            keyArgs: ["scheduledInterviewId"],
            merge(existing, incoming) {
              return [...(existing ?? []), ...incoming];
            },
          },
        },
      },
      UserMembership: {
        fields: {
          activity: {
            keyArgs: ["userMembershipId"],
            merge(existing, incoming) {
              return [...(existing ?? []), ...incoming];
            },
          },
          notifications: {
            keyArgs: false,
            merge(existing, incoming, { args, readField }) {
              // If no cursor, it's a fresh request
              if (!args?.cursor) {
                return incoming;
              }
              const merged = [...(existing ?? []), ...(incoming ?? [])];
              return uniqWith(merged, (a, b) => {
                const aId = readField("id", a);
                const bId = readField("id", b);
                return !!aId && !!bId && aId === bId;
              });
            },
          },
        },
      },
      UserMembershipInterviewerPool: {
        fields: {
          activity: {
            keyArgs: ["interviewerPoolId", "userMembershipId"],
            merge(existing, incoming) {
              return [...(existing ?? []), ...incoming];
            },
          },
        },
      },
      SchedulingAlgorithmRun: {
        merge(existing, incoming, { readField, mergeObjects }) {
          if (!existing) {
            return mergeObjects(existing, incoming);
          }
          const existingVersion = readField("updatedAt", existing);
          const incomingVersion = readField("updatedAt", incoming);

          if (!existingVersion || !incomingVersion) {
            return mergeObjects(existing, incoming);
          }

          return existingVersion > incomingVersion
            ? existing
            : mergeObjects(existing, incoming);
        },
      },
      InterviewRequirement: {
        fields: {
          interviewerRequirements: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          videoConferencingSettings: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      InterviewPanelRequirement: {
        fields: {
          interviewRequirements: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_WEBSOCKET_URL ?? "wss://localhost:3006",
      shouldRetry: () => true,
      connectionParams: {
        currentOrganizationId: await getCookie(ORGANIZATION_ID_COOKIE_NAME),
        impersonationMembershipId: await getCookie(
          IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME
        ),
      },
      onNonLazyError(errorOrCloseEvent) {
        console.error("Error in graphql socket connection", errorOrCloseEvent);
      },
    })
  );

  // Send subscription events to websockets + everything else over http
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    createIsomorphLink()
  );

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    connectToDevTools: process.env.NODE_ENV === "development",
    link: ApolloLink.from([createSentryErrorLink(), splitLink]),
    cache,
    ssrForceFetchDelay: 100,
    name: "guide-web",
  });
}

export async function initializeApollo({
  state: initialState = null,
}: {
  state: Record<string, unknown> | null;
}) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? (await createApolloClient());

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: GuideApolloClient,
  pageProps: AppProps["pageProps"]
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: AppProps["pageProps"]) {
  const [client, setClient] = useState<GuideApolloClient>();
  const router = useRouter();

  const state = pageProps[APOLLO_STATE_PROP_NAME];
  useEffect(() => {
    initializeApollo({ state })
      .then(setClient)
      .catch((err) => {
        captureSentryError(err, {
          title: "initializeApollo",
        });
        console.error(err);
        router.push("/error");
      });
  }, [state, router]);

  return {
    client,
    clearClientStore: async () => {
      await client?.clearStore();
      clearPersistedCache();
    },
  };
}
