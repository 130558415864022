import {
  Popover,
  PopoverDisclosure,
  PopoverDisclosureProps,
  PopoverProps as AriakitPopoverProps,
  PopoverProvider,
  PopoverProviderProps,
  PopoverStoreProps,
  usePopoverStore as useAriakitPopoverStore,
} from "@ariakit/react";
import * as Ariakit from "@ariakit/react";

import { Component, createComponentUtils } from "../__utils/atlas";

// config
// ------

const COMPONENT_NAME = "Popover-V2";

export const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// hooks
// -----

export function usePopoverStore(props?: PopoverStoreProps) {
  return useAriakitPopoverStore(props);
}

export type PopoverStore = ReturnType<typeof usePopoverStore>;

// root
// ----
export type RootProps = PopoverProviderProps;

export const Root = createComponent<Component<RootProps>>(
  (providerProps) => {
    return <PopoverProvider {...providerProps} />;
  },
  { forwardRef: false, treeName: "Root" }
);

// trigger
// ------
export const Trigger = createComponent<Component<PopoverDisclosureProps>>(
  ({ children, ...props }) => {
    const popover = Ariakit.usePopoverContext();

    if (!popover) {
      throw new Error("Popover must be wrapped in PopoverRoot");
    }

    return <PopoverDisclosure {...props}>{children}</PopoverDisclosure>;
  },
  { treeName: "Trigger" }
);

// content
// -------
export type ContentProps = AriakitPopoverProps;

export const Content = createComponent<Component<ContentProps>>(
  ({ children, ...props }) => {
    const popover = Ariakit.usePopoverContext();

    if (!popover) {
      throw new Error("Popover must be wrapped in PopoverRoot");
    }

    return (
      <Popover {...props} className={el`content`}>
        {/** TODO */}
        {/* <PopoverArrow className={el`arrow`} /> */}
        <div className={el`content-body`}>{children}</div>
      </Popover>
    );
  },
  { treeName: "Content" }
);
