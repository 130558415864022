import { SerializedEditorState } from "lexical";
import {
  Children,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
} from "react";

import { createRenderer, CreateRendererConfig } from "./create-renderer";

// text utils
// ----------

const Stub = () => (_: { children?: ReactNode }) => null;

/** The text renderer components to be used in the output of node renderers. */
export const Txt = {
  /** Used for the root node. */
  Root: Stub(),
  /** A block that will contain empty lines around it. */
  Block: Stub(),
  /** A line of text that will end with a line break. */
  Line: Stub(),
  /** A line break. */
  LineBreak: Stub(),
  /** A piece of text that is outputted as-is. */
  Text: Stub(),
};

// text renderer
// -------------

function renderAsText(element: ReactElement | null) {
  if (element === null) return "";
  const parts: string[] = [];

  const { type } = element;
  const children = element.props.children as ReactNode;

  const childParts =
    Children.map(children, (child) => {
      if (isValidElement(child)) return renderAsText(child);
      if (child) return String(child);
      return "";
    }) ?? [];

  if (type === Txt.Root) parts.push(...childParts);
  else if (type === Txt.Block) parts.push("\n", ...childParts, "\n");
  else if (type === Txt.Line) parts.push(...childParts, "\n");
  else if (type === Txt.LineBreak) parts.push("\n");
  else if (type === Txt.Text || type === Fragment) parts.push(...childParts);
  else throw new Error(`Unknown element type: ${type}`);

  return parts.join("");
}

/** Creates a renderer for a content editor state. */
export function createTextRenderer({
  nodeRenderers,
  theme,
  renderId: globalRenderId,
}: CreateRendererConfig) {
  const renderer = createRenderer({
    nodeRenderers,
    theme,
    renderId: globalRenderId,
  });
  return async function textRenderer(
    state: SerializedEditorState,
    renderId?: string
  ) {
    const element = await renderer(state, renderId);
    if (!element) return null;
    return (
      renderAsText(element)
        // TODO: only trim trailing new lines, not whitespace because it could be an indented list item (or just intentional space)
        .trim()
        // normalize to never have more than one empty line in a row
        .replace(/\n{2,}/g, "\n\n")
    );
  };
}
