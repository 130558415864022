import { ActivityVerb } from "shared/activity/types";

import { NotificationType } from "../utils/types";
import { BaseNotificationDisplay } from "./BaseNotificationDisplay";
import { ChatMessageNotificationDisplay } from "./ChatMessageNotificationDisplay";
import { CommentNotificationDisplay } from "./CommentNotificationDisplay";

export function getNotificationDisplay(notification: NotificationType) {
  const { verb } = notification;

  // Interview related notifications
  switch (verb) {
    case ActivityVerb.CommentCreated:
      return CommentNotificationDisplay;
    case ActivityVerb.ChatMessagePosted:
      return ChatMessageNotificationDisplay;

    default:
      return BaseNotificationDisplay;
  }
}
