import React from "react";

import { TOUR_Z_INDICES } from "../TourRenderer";
import { SerializedElement } from "../utils/serialize";
import { useDomObservers } from "../utils/useDomObservers";

/**
 * Takes the radius css property string and converts it to a pixel number.
 * This handles edge cases around border radius larger than the size of the rect in which
 * case a full circle would be rendered.
 *
 * @param str the css border radius property
 * @param rect bounding rect representing the rendered element
 * @returns the border radius in pixels
 */
function radiusFromString(
  str: string | undefined,
  rect: { width: number; height: number }
) {
  if (!str) {
    return 0;
  }

  // The value is pixels already so we can just convert to a number and use.
  if (str.endsWith("px")) {
    const num = Number(str.substring(0, str.length - 2));
    // Border radius cannot be more than half of the smallest edge.
    return Math.min(num, rect.width / 2, rect.height / 2);
  }

  if (str.endsWith("%")) {
    // Take percentage and convert to a decimal. The actual border radius can never be more than 50%.
    const perc = Math.min(Number(str.substring(0, str.length - 1)) / 100, 0.5);
    // Border radius will be whatever is the smallest possible value.
    return Math.min(perc * rect.width, perc * rect.height);
  }

  // This should never happen because em/rem etc should have already been converted to pixels before
  // now. However, in order to gracefully handle this we will just
  // assume there is no border radius.
  return 0;
}

/**
 * Build a path backwards from a given elements details. This handles border radiuses, including
 * different border radius for each corner. Note: the returned path is drawn
 * counter-clockwise. This is so that it can be used as a cut out from another path.
 *
 * @param element the element to be rendered. Note: this is a serialized element so can render iframe elements.
 * @returns svg path to use to draw this element
 */
function pathFromElement(element: SerializedElement | null) {
  if (!element) {
    return "";
  }
  const { rect: rectRaw, style } = element;
  const rect = {
    x: rectRaw.x - 1,
    y: rectRaw.y - 1,
    width: rectRaw.width + 2,
    height: rectRaw.height + 2,
  };

  const radiusTl = radiusFromString(style.borderTopLeftRadius, rect);
  const radiusBl = radiusFromString(style.borderBottomLeftRadius, rect);
  const radiusBr = radiusFromString(style.borderBottomRightRadius, rect);
  const radiusTr = radiusFromString(style.borderTopRightRadius, rect);

  /**
   * Crude representation of a rectangle with curved corners for reference in drawing code.
   *     A         H
   *     -----------
   *    /           \
   * B /             \  G
   *  |               |
   * C \             /  F
   *    \           /
   *     -----------
   *     D          E
   *
   * Quick svg path reference;
   * M[x,y] - Move to position x,y
   * h[l] - Draw a horizontal line of length l
   * v[l] - Draw a vertical line of length l
   * a[a,b] 0 0 0 [x,y] - Draw an arc with radius [a,b] distance [x,y]. We can ignore the other parameters (0s) for sake of this function.
   */

  // Path in array form so that it can be clearly annotated. We really just want the concatenation of these.
  const result = [
    `M${rect.x + radiusTl},${rect.y}`, // Move to the top left of the rectangle offset by the border radius, position A in diagram
    `a${radiusTl},${radiusTl} 0 0 0 -${radiusTl},${radiusTl}`, // Draw top left arc down to position B; if radius is 0 will do nothing
    `v${rect.height - (radiusTl + radiusBl)}`, // Draw vertical line down to C
    `a${radiusBl},${radiusBl} 0 0 0 ${radiusBl},${radiusBl}`, // Draw bottom left arc down to position D
    `h${rect.width - (radiusBl + radiusBr)}`, // Draw horizontal line across to position E
    `a${radiusBr},${radiusBr} 0 0 0 ${radiusBr},-${radiusBr}`, // Draw bottom right arc up to F
    `v-${rect.height - (radiusBr + radiusTr)}`, // Draw vertical line up from F to G (negative = up)
    `a${radiusTr},${radiusTr} 0 0 0 -${radiusTr},-${radiusTr}`, // Draw top right arc up to H
    `z`, // Close the path; this will draw line back to starting point which is effectively a horizontal line across to A
  ];

  return result.join(" ");
}

/**
 * Highlights given elements on the screen. Or more specifically greys everything else out. Will allow click events through
 * to the highlighted elements but no where else (assuming
 * that they're below this element in z-index).
 */
export function Highlighter({ selectors }: { selectors: string[] }) {
  const targets = useDomObservers(selectors);

  const paths = targets.map((target) => pathFromElement(target));

  return (
    <svg
      className="fixed top-0 left-0 w-screen h-screen opacity-50 pointer-events-none"
      style={{
        zIndex: TOUR_Z_INDICES.HIGHLIGHTS,
      }}
    >
      {/* Renders a rectangle of the page width + height with any paths cut out of it */}
      <path
        className="pointer-events-auto"
        d={`M0,0 h${window.innerWidth} v${window.innerHeight} h-${
          window.innerWidth
        } z ${paths.join(" ")}`}
      />
    </svg>
  );
}
