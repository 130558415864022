import { NetworkStatus, useSubscription } from "@apollo/client";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { Menu } from "@resource/atlas/menu-v2";
import { Tabs } from "@resource/atlas/tabs-v2";
import { gql } from "generated/graphql-codegen";
import { NotificationForDisplayFragment } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

import { NoNotifications } from "./NoNotifications";
import { NotificationDisplay } from "./NotificationDisplay";
import { useNotificationMenuItems } from "./useNotificationMenuItems";
import { mapNotificationFragmentToNotification } from "./utils/mapping";

export const NOTIFICATIONS_QUERY = gql(`
  query NotificationsForNotificationList($cursor: String, $take: Int) {
    currentUser {
      id
      currentUserMembership {
        id
        notifications(cursor: $cursor, take: $take) {
          ...NotificationForDisplay
        }
      }
    }
  }
`);

export const NEW_NOTIFICATION_SUB = gql(`
  subscription NewNotification {
    notificationCreated {
      ...NotificationForDisplay
    }
  }
`);

export const UPDATED_NOTIFICATION_SUB = gql(`
  subscription UpdatedNotification {
    notificationUpdated {
      ...NotificationForDisplay
    }
  }
`);

const PAGE_SIZE = 20;

const MARK_NOTIFICATIONS_AS_READ = gql(`
  mutation MarkNotificationsAsRead($ids: [String!]!) {
    markNotificationsAsRead(ids: $ids) {
      id
      readAt
    }
  }
`);

export function NotificationList({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) {
  const [activeTab, setActiveTab] = useState("all");
  const [menuOpen, setMenuOpen] = useState(false);
  const { ref: loadMoreRef, inView } = useInView();

  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery(
    NOTIFICATIONS_QUERY,
    {
      variables: {
        cursor: null,
        take: PAGE_SIZE,
      },
    }
  );

  const notifications = useMemo(() => {
    return data?.currentUser?.currentUserMembership?.notifications ?? [];
  }, [data]);

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);

  const handleMarkAllAsRead = useCallback(async () => {
    const unreadNotifications = notifications.filter((n) => !n.readAt);
    if (unreadNotifications.length === 0) return;

    try {
      await markNotificationsAsRead({
        variables: {
          ids: unreadNotifications.map((n) => n.id),
        },
      });
    } catch (err) {
      console.error("Error marking notifications as read:", err);
    }
  }, [notifications, markNotificationsAsRead]);

  const handleMarkAsRead = useCallback(
    async (notificationId: string) => {
      try {
        await markNotificationsAsRead({
          variables: {
            ids: [notificationId],
          },
        });
      } catch (err) {
        console.error("Error marking notification as read:", err);
      }
    },
    [markNotificationsAsRead]
  );

  const menuItems = useNotificationMenuItems({
    markAllAsRead: handleMarkAllAsRead,
  });

  useSubscription(UPDATED_NOTIFICATION_SUB, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const updatedNotification = subscriptionData.data?.notificationUpdated;
      if (!updatedNotification) return;

      client.cache.modify({
        id: client.cache.identify(updatedNotification),
        fields: {
          readAt: () => updatedNotification.readAt,
          activity: () => updatedNotification.activity,
          userMembership: () => updatedNotification.userMembership,
          createdAt: () => updatedNotification.createdAt,
          eventTime: () => updatedNotification.eventTime,
        },
      });
    },
  });
  useSubscription(NEW_NOTIFICATION_SUB, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const notificationCreated = subscriptionData.data?.notificationCreated;
      if (!notificationCreated || !data?.currentUser?.currentUserMembership)
        return;

      // Use cache.modify instead of read/writeFragment
      client.cache.modify({
        id: `UserMembership:${data.currentUser.currentUserMembership.id}`,
        fields: {
          notifications(existingNotifications = []) {
            const notificationExists = existingNotifications.some(
              (n: NotificationForDisplayFragment) =>
                n.id === notificationCreated.id
            );
            if (notificationExists) return existingNotifications;

            // Add new notification to beginning of list
            return [notificationCreated, ...existingNotifications];
          },
        },
      });
      refetch();
    },
  });

  const loadNextPage = useCallback(async () => {
    if (loading || !notifications.length) return;

    const lastNotification = notifications[notifications.length - 1];

    try {
      await fetchMore({
        variables: {
          cursor: lastNotification.eventTime,
          take: PAGE_SIZE,
        },
      });
    } catch (err) {
      console.error("Error loading more notifications:", err);
    }
  }, [fetchMore, loading, notifications]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inView) {
        loadNextPage();
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [inView, loadNextPage]);

  const filteredNotifications = activeTab === "all" ? notifications : [];
  const sortedNotifications = _(filteredNotifications)
    .uniqBy("id")
    .orderBy("activity.eventTime", "desc")
    .value();

  const renderContent = () => {
    if (loading && notifications.length === 0) {
      return (
        <div className="mt-12 flex items-center justify-center w-full">
          <LoadingIndicator size="small" />
        </div>
      );
    }

    if (error) {
      return <p>Error loading notifications.</p>;
    }

    if (sortedNotifications.length === 0) {
      return (
        <NoNotifications
          title={
            activeTab === "all"
              ? "You don't have any notifications"
              : "No suggestions to review"
          }
          subtitle={
            activeTab === "all"
              ? "No notifications at the moment. We'll keep you posted!"
              : "You don't have any suggestions to review. We'll keep you posted!"
          }
        />
      );
    }

    return (
      <>
        {sortedNotifications.map((notification) => (
          <NotificationDisplay
            key={notification.id}
            notification={mapNotificationFragmentToNotification(notification)}
            onClick={() => {
              handleMarkAsRead(notification.id);
              setOpen(false);
            }}
          />
        ))}
        {networkStatus === NetworkStatus.fetchMore && (
          <div className="mt-6 flex items-center justify-center w-full">
            <LoadingIndicator size="small" />
          </div>
        )}
        <div ref={loadMoreRef} className="h-8" />
      </>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg max-w-2xl">
      <div className="flex justify-between items-center gap-2 px-6 pt-6">
        <h3 className="text-h3 font-semibold">Notifications</h3>
        <Menu.Root open={menuOpen} setOpen={setMenuOpen}>
          <Menu.Button icon={atlasEllipsisHorizontal} isGhost size="sm" />
          <Menu.Menu>{menuItems}</Menu.Menu>
        </Menu.Root>
      </div>

      <Tabs.Root selectedId={activeTab} onSelectedChange={setActiveTab}>
        <Tabs.List className="!pb-0">
          <Tabs.Tab id="all">All activity</Tabs.Tab>
          <Tabs.Tab id="needs-review">Needs review</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel tabId={activeTab}>
          <div className="p-3 border-t border-gray-border overflow-y-auto max-h-[50vh]">
            {renderContent()}
          </div>
        </Tabs.Panel>
      </Tabs.Root>
    </div>
  );
}
