import clsx from "clsx";
import { DateTime } from "luxon";
import React from "react";

import { BaseNotificationDisplayProps } from "./BaseNotificationDisplay";

function renderScheduledDate(scheduledAt: string) {
  const scheduledDate =
    DateTime.fromISO(scheduledAt).setZone("America/Chicago");
  const formattedTime = scheduledDate.toLocaleString({
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const now = DateTime.now().setZone("America/Chicago");
  const diff = scheduledDate.diff(now, "minutes").minutes;
  const relativeTime = diff > 0 ? `in ${Math.round(diff)} mins` : "sent";

  return `Scheduled to send at ${formattedTime} CST (${relativeTime})`;
}

export function ChatMessageNotificationDisplay({
  notification,
  className,
}: BaseNotificationDisplayProps) {
  const { actor, chatMessage } = notification;

  return (
    <div className={clsx(className, "space-y-.5")}>
      <p className="text-body-md text-dark">
        <span className="text-body-md-heavy">{actor?.name}</span> sent a message{" "}
        <span className="text-body-md-heavy">
          {chatMessage?.automatedAction?.requireReview
            ? "(requires review)"
            : "(no action required)"}
        </span>
      </p>
      {chatMessage?.automatedAction?.scheduledAt && (
        <p className="text-body-sm text-subtle">
          {renderScheduledDate(chatMessage.automatedAction.scheduledAt)}
        </p>
      )}
    </div>
  );
}
