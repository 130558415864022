import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBell } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { useMemo } from "react";

type UseNotificationMenuItemsProps = {
  markAllAsRead: () => void;
};

export const useNotificationMenuItems = ({
  markAllAsRead,
}: UseNotificationMenuItemsProps) => {
  return useMemo(
    () => [
      <Menu.Item
        key="mark-all-read"
        size="compact"
        leadingContent={<Icon content={atlasBell} className="text-subtle" />}
        onClick={() => markAllAsRead()}
      >
        Mark all as read
      </Menu.Item>,
    ],
    [markAllAsRead]
  );
};
