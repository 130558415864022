import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TourConfig, TourStageConfig } from "./types";
import { useDomObserver } from "./utils/useDomObserver";

export type TourManager = {
  tour: TourConfig;
  start: () => unknown;
  advance: () => unknown;
  isStarted: boolean;
  isLastStage: boolean;
  isFinished: boolean;
  isReady: boolean;
  currentStage: TourStageConfig | null;
  hasBeenShown: boolean;
  dismiss: () => void;
  hide: () => void;
  learnMoreUrl: string | undefined;
};

export function useTourManager(tour: TourConfig): TourManager {
  const router = useRouter();
  const [stage, setStage] = useState(-1);
  const [hasBeenShown, setHasBeenShown] = useState(() => {
    return localStorage.getItem(`__guide__${tour.storageKey}`) === "true";
  });
  const isLastStage = useMemo(() => {
    return stage >= tour.stages.length - 1;
  }, [tour.stages, stage]);

  const markTourAsShown = useCallback(() => {
    localStorage.setItem(`__guide__${tour.storageKey}`, "true");
    setHasBeenShown(true);
  }, [tour.storageKey]);

  const start = useCallback(() => {
    if (stage < 0) {
      setStage(0);
    }
  }, [stage, setStage]);

  const advance = useCallback(() => {
    tour.stages[stage]?.onAdvance?.();

    // If this will be the last stage, mark the tour as shown
    if (isLastStage) {
      markTourAsShown();
    } else {
      setStage((s) => s + 1);
    }
  }, [setStage, isLastStage, stage, tour.stages, markTourAsShown]);
  // Dismiss should mark the tour complete and end the tour only
  const dismiss = useCallback(() => {
    markTourAsShown();
    tour.stages[stage]?.onDismiss?.();
    setStage(tour.stages.length);
  }, [markTourAsShown, stage, tour.stages]);

  const hide = useCallback(() => {
    setStage(-1);
  }, []);

  // Get element for advance on functionality
  const currentStage = tour.stages[stage];
  const advanceOnSelector = currentStage?.advanceOn?.selector;
  const elementForCurrentStage = useDomObserver(advanceOnSelector);

  // Handle advance on functionality
  useEffect(() => {
    if (!currentStage?.advanceOn || !elementForCurrentStage) return () => {};

    const { event = "click", selector } = currentStage.advanceOn;

    // bring back code to find element by selector
    const element = document.querySelector(selector);
    if (!element) return () => {};

    const handleEvent = () => {
      if (isLastStage) {
        dismiss();
      } else {
        advance();
      }
    };

    element.addEventListener(event, handleEvent);
    return () => {
      element.removeEventListener(event, handleEvent);
    };
  }, [
    stage,
    tour.stages,
    advance,
    isLastStage,
    dismiss,
    currentStage,
    elementForCurrentStage,
  ]);

  useEffect(() => {
    if (router.pathname.startsWith("/google-calendar-permissions")) {
      hide();
    }
  }, [hide, router]);

  // We're ready if either we're not waiting for anything or the thing we're waiting for exists
  const isReady = useMemo(
    () => tour.stages[stage]?.isReady ?? true,
    [tour.stages, stage]
  );

  return {
    tour,
    start,
    advance,
    isLastStage: stage === tour.stages.length - 1,
    dismiss,
    hide,
    isStarted: stage >= 0,
    isFinished: stage >= tour.stages.length,
    isReady,
    hasBeenShown,
    currentStage: tour.stages[stage] ?? null,
    learnMoreUrl: tour.learnMoreUrl,
  };
}
