import {
  Menu as MenuComponent,
  MenuButton,
  type MenuButtonProps,
  MenuGroup,
  type MenuGroupProps,
  MenuItem,
  type MenuItemProps,
  type MenuProps,
  MenuRoot,
  type MenuRootProps,
  MenuSeparator,
  type MenuSeparatorProps,
} from "./Menu";

export type {
  MenuButtonProps,
  MenuGroupProps,
  MenuItemProps,
  MenuProps,
  MenuRootProps,
  MenuSeparatorProps,
};

export const Menu = {
  Menu: MenuComponent,
  Root: MenuRoot,
  Button: MenuButton,
  Item: MenuItem,
  Group: MenuGroup,
  Separator: MenuSeparator,
};
