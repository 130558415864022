/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
} from "ariakit";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";

import { atlasClose } from "../../icons/atlas";
import {
  createComponentUtils,
  createRootContext,
  usePExtendedState,
} from "../__utils/atlas";
import {
  createDefaultProps,
  mergePropsIntoSingleChild,
} from "../__utils/react";
import { Button } from "../button/Button";
import { useDialogContext } from "./DialogContext";
import { DialogLayout } from "./DialogLayout";
import type {
  AtlasDialogContentComponent,
  AtlasDialogContentProps,
  AtlasDialogLayoutHeader,
  AtlasDialogRootComponent,
  AtlasDialogState,
  AtlasDialogTriggerComponent,
  AtlasDialogViewComponent,
  AtlasDialogViewProps,
} from "./types";
import { useDialogState } from "./use-dialog-state";
import {
  DialogViewsIntegration,
  useDialogViewsContext,
} from "./use-dialog-views";

// config
// ------

const COMPONENT_NAME = "Dialog";

const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// context
// -------

export type DialogRootContext = {
  dialog: AtlasDialogState;
  hasView: boolean;
  setHasView: (value: boolean) => void;
};

export const { RootContext, RootProvider, useRootContext } =
  createRootContext<DialogRootContext>(COMPONENT_NAME);

// root
// ----

/**
 * The root of the dialog component. Supports a trigger and content.
 *
 * @example
 * <Dialog.Root>
 *   <Dialog.Trigger>
 *     <Button>Open dialog</Button>
 *   </Dialog.Trigger>
 *   <Dialog.Content
 *     header={header}
 *     footer={footer}
 *   >
 *     {body}
 *   </Dialog.Content>
 * </Dialog.Root>
 */
export const Root = createComponent<AtlasDialogRootComponent>(
  ({ children, state, ...stateProps }) => {
    const dialog = useDialogState(stateProps);

    const [hasView, setHasView] = useState(false);

    const contextValue = useMemo(
      () => ({ dialog: state ?? dialog, hasView, setHasView }),
      [dialog, hasView, state]
    );

    return (
      <RootProvider value={contextValue}>
        <DialogOpenReporter>{children}</DialogOpenReporter>
      </RootProvider>
    );
  },
  { forwardRef: false, treeName: "Root" }
);

// Report to global provider if any dialog is open

function DialogOpenReporter({ children }: { children: React.ReactNode }) {
  const { incrementDialogCount, decrementDialogCount } = useDialogContext();
  const prevOpenRef = useRef<boolean | null>(false);
  const { dialog } = useRootContext();

  useEffect(() => {
    if (dialog.open !== prevOpenRef.current) {
      if (dialog.open) {
        incrementDialogCount();
      } else {
        decrementDialogCount();
      }
      prevOpenRef.current = dialog.open;
    }
  }, [dialog, incrementDialogCount, decrementDialogCount]);

  return <>{children}</>;
}

// trigger
// -------

/**
 * The trigger of a dialog. Must be a child of `<Dialog.Root />`. Expects the
 * button to be used as trigger, passed as a single child.
 *
 * @example
 * <Dialog.Trigger>
 *   <Button>Open dialog</Button>
 * </Dialog.Trigger>
 */
export const Trigger = createComponent<AtlasDialogTriggerComponent>(
  ({ children, ...p0 }) => {
    const [dialog, props] = usePExtendedState(p0, useRootContext().dialog);

    return (
      <DialogDisclosure {...props} state={dialog}>
        {(triggerProps) => mergePropsIntoSingleChild(triggerProps, children)}
      </DialogDisclosure>
    );
  },
  { treeName: "Trigger" }
);

// view
// ----

const DEFAULT_VIEW_PROPS = createDefaultProps<AtlasDialogViewProps>()({
  hasPadding: true,
} as const);

const CLOSE_BUTTON_ICON = atlasClose;

/**
 * A view of the dialog. Must be a child of `<Dialog.Content />` and it's optional.
 *
 * @example
 * <Dialog.View
 *   header={header}
 *   footer={footer}
 * >
 *   {body}
 * </Dialog.View>
 */
export const View = createComponent<AtlasDialogViewComponent>(
  ({
    hasPadding = DEFAULT_VIEW_PROPS.hasPadding,
    header,
    footer,
    bodyProps,
    // @ts-expect-error - Private prop.
    __preventRegister,
    ...props
  }) => {
    // register the view in the context
    const { setHasView } = useRootContext();
    useEffect(() => {
      if (!__preventRegister) setHasView(true);
      return () => {
        if (!__preventRegister) setHasView(false);
      };
    }, [__preventRegister, setHasView]);

    // prepare the header
    const dialogViewsContext = useDialogViewsContext();
    const resolvedHeader: AtlasDialogLayoutHeader = useMemo(
      () => ({
        ...header,
        leftActions: dialogViewsContext.headerBackAction ??
          header?.backAction ?? (
            <DialogDismiss>
              {(dismissProps) => (
                <Button
                  isGhost
                  size="small"
                  icon={CLOSE_BUTTON_ICON}
                  aria-label="Close dialog"
                  {...dismissProps}
                />
              )}
            </DialogDismiss>
          ),
        renderTitle: (headingProps) => <DialogHeading {...headingProps} />,
      }),
      [header, dialogViewsContext.headerBackAction]
    );

    return (
      <DialogLayout
        header={header === null ? undefined : resolvedHeader}
        footer={footer}
        hasPadding={hasPadding}
        bodyProps={bodyProps}
        {...props}
        className={clsx(el`layout`, props.className)}
      />
    );
  },
  { treeName: "View" }
);

// content
// -------

const DEFAULT_CONTENT_PROPS = createDefaultProps<AtlasDialogContentProps>()({
  size: "small",
  variant: "default",
} as const);

/**
 * The content of a dialog. Must be a child of `<Dialog.Root />`. Positioned like a standard
 * dialog by default, can be positioned like a sheet with `variant="sheet"`.
 *
 * @example
 * <Dialog.Content
 *   header={header}
 *   footer={footer}
 * >
 *   {body}
 * </Dialog.Content>
 */
export const Content = createComponent<AtlasDialogContentComponent>(
  ({
    size = DEFAULT_CONTENT_PROPS.size,
    variant = DEFAULT_CONTENT_PROPS.variant,
    hasPadding,
    header,
    footer,
    children,
    backdropProps,
    bodyProps,
    // @ts-expect-error - Private prop.
    __dialogViewsIntegration,
    ...p0
  }) => {
    const { dialog: originalDialog, hasView } = useRootContext();

    const [dialog, props] = usePExtendedState(p0, originalDialog);

    return (
      <DialogViewsIntegration props={__dialogViewsIntegration} dialog={dialog}>
        {dialog.mounted ? (
          <Dialog
            // TODO: remove once ariakit fixes the escape propagation issue
            data-atlas-dialog=""
            backdropProps={{
              className: clsx(
                `${el`backdrop`} size-${size} variant-${variant}`,
                backdropProps?.className
              ),
              ...backdropProps,
            }}
            state={dialog}
            {...props}
            className={clsx(
              `${el`content`} size-${size} variant-${variant}`,
              props.className
            )}
          >
            {(dialogProps) => (
              <div {...dialogProps}>
                {hasView ? (
                  children
                ) : (
                  // render a view if it hasn't been passed by the user,
                  // this way it's optional
                  <View
                    header={header}
                    footer={footer}
                    hasPadding={hasPadding}
                    bodyProps={bodyProps}
                    // @ts-expect-error - Private prop.
                    __preventRegister
                  >
                    {children}
                  </View>
                )}
              </div>
            )}
          </Dialog>
        ) : null}
      </DialogViewsIntegration>
    );
  },
  { treeName: "Content" }
);
