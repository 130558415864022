import { TourRenderer } from "client/components/tours/TourRenderer";
import { TourConfig } from "client/components/tours/types";
import { useTourManager } from "client/components/tours/useTourManager";
import { useDomObserver } from "client/components/tours/utils/useDomObserver";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

const selectors = {
  orgSettingsNav: ".tours__org-settings-nav",
  interviewerTagsTab: ".tours__interviewer-tags-tab",
};

export function AdvancedInterviewerTagsIntroTour() {
  const orgSettingsNav = useDomObserver(selectors.orgSettingsNav);
  const router = useRouter();

  const {
    [FeatureFlags.INTERVIEWER_TAGS]: interviewerTagsEnabled,
    [FeatureFlags.TAGS_PRODUCT_TOURS]: tagsProductToursEnabled,
  } = useFlags();

  const tour = useMemo(
    (): TourConfig => ({
      storageKey: "organization-settings-interviewer-tags-tour",
      learnMoreUrl:
        "https://intercom.help/guide-v2-help-center/en/articles/10262620-using-interviewer-tags-and-smart-match-interviewer-selection",
      stages: [
        {
          instructions: {
            content: (
              <div className="space-y-4">
                <div className="space-y-1">
                  <p className="text-subtle text-h5">New Release</p>
                  <h4 className="text-h4">
                    🚀 Interviewer Tags & &quot;Smart Match&quot; Interviewer
                    Selection
                  </h4>
                </div>
                <p className="mt-2">
                  Introducing interviewer tags, a more powerful way select the
                  right interviewers without the manual work.
                </p>
              </div>
            ),
            image: "/images/smart-match-filters.png",
            dialogVariant: "narrator",
            buttonText: "Start tour",
          },
          advanceOn: {
            selector: selectors.orgSettingsNav,
            event: "click",
          },
          highlights: [],
        },

        {
          instructions: {
            content: (
              <>
                <span className="text-body-md-heavy">
                  Create and manage tags
                </span>
                <p className="mt-2">
                  Head to Organization Settings and select &quot;Interviewer
                  tags&quot; to create and manage tags and tag groups.
                </p>
              </>
            ),
            target: selectors.orgSettingsNav,
            placement: "right",
            buttonText: "Take me to Tags",
          },
          onAdvance: () => {
            router.push("/settings/tags");
          },
          highlights: [selectors.orgSettingsNav],
        },
      ],
    }),
    [router]
  );

  const tourState = useTourManager(tour);

  useEffect(() => {
    if (
      !tourState.isStarted &&
      !tourState.isFinished &&
      orgSettingsNav &&
      interviewerTagsEnabled &&
      tagsProductToursEnabled
    ) {
      tourState.start();
    }
  }, [
    orgSettingsNav,
    tourState,
    interviewerTagsEnabled,
    tagsProductToursEnabled,
  ]);

  return <TourRenderer tour={tourState} />;
}
