import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { usePopper } from "react-popper";

import { TOUR_Z_INDICES } from "../TourRenderer";
import { useDomObserver } from "../utils/useDomObserver";
import { MessageBoxBaseProps, RawMessageBox } from "./RawMessageBox";

type MessageBoxProps = ComponentPropsWithoutRef<"div"> &
  MessageBoxBaseProps & {
    selector: string;
    placement?:
      | "left"
      | "right"
      | "top"
      | "bottom"
      | "top-start"
      | "top-end"
      | "bottom-start"
      | "bottom-end"
      | "right-start"
      | "right-end"
      | "left-start"
      | "left-end";
  };

export function MessageBox({ children, ...messageBoxProps }: MessageBoxProps) {
  const { selector, placement } = messageBoxProps;
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const target = useDomObserver(selector);

  const { styles, attributes, state, update } = usePopper(
    targetElement,
    popperElement,
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        {
          name: "offset",
          options: {
            offset: [0, 16],
          },
        },
      ],
      placement: placement ?? "left",
    }
  );

  useEffect(() => {
    update?.();
  }, [target, update]);

  const arrow = (
    <div
      ref={setArrowElement}
      style={{
        ...styles.arrow,
        right: state?.placement.startsWith("left") ? "-4px" : undefined,
        left: state?.placement.startsWith("right") ? "-4px" : undefined,
        top: state?.placement.startsWith("bottom") ? "-4px" : undefined,
        bottom: state?.placement.startsWith("top") ? "-4px" : undefined,
      }}
      className="arrow absolute w-2 h-2 bg-white invisible before:visible before:content-[''] before:absolute before:w-2 before:h-2 before:bg-white before:rotate-45"
    />
  );

  if (!target) {
    return null;
  }

  return (
    <>
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          zIndex: TOUR_Z_INDICES.CONTENT,
        }}
        {...attributes.popper}
      >
        <RawMessageBox arrow={arrow} {...messageBoxProps}>
          {children}
        </RawMessageBox>
      </div>
      <div
        ref={setTargetElement}
        className="pointer-events-none block"
        style={{
          position: "fixed",
          left: target.rect.x,
          top: target.rect.y,
          width: target.rect.width,
          height: target.rect.height,
        }}
      />
    </>
  );
}
