import { useSubscription } from "@apollo/client";
import * as Ariakit from "@ariakit/react";
import Badge from "@resource/atlas/badge/Badge";
import { atlasBell } from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Popover, usePopoverStore } from "@resource/atlas/popover-v2";
import { gql } from "generated/graphql-codegen";
import React, { useState } from "react";
import useQuery from "utils/useQuery";

import { NotificationList } from "./NotificationList";

export const NOTIFICATION_COUNT_QUERY = gql(`
  query NotificationCountForNavButton {
    currentUser {
      id
      currentUserMembership {
        id
        unreadNotificationCount
      }
    }
  }
`);

export const NOTIFICATION_COUNT_SUBSCRIPTION = gql(`
  subscription NotificationCountSubscription {
    currentNotificationCount
  }
`);

export function AppNavNotificationPopover() {
  const store = usePopoverStore({
    placement: "right-start",
  });
  const state = Ariakit.useStoreState(store);
  const [unreadCount, setUnreadCount] = useState(0);
  useQuery(NOTIFICATION_COUNT_QUERY, {
    onCompleted: (d) => {
      setUnreadCount(
        d?.currentUser?.currentUserMembership?.unreadNotificationCount ?? 0
      );
    },
  });

  useSubscription(NOTIFICATION_COUNT_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const count = subscriptionData.data?.currentNotificationCount;
      if (typeof count === "number") {
        setUnreadCount(count);
      }
    },
  });

  return (
    <Popover.Root store={store}>
      <Popover.Trigger className="w-full">
        <ListItem
          isInteractive
          className="w-full"
          isSelected={state.open}
          icon={atlasBell}
          isSubtleIcon
        >
          <div className="flex items-center justify-between gap-2 w-full">
            <span>Notifications</span>
            {unreadCount > 0 && (
              <Badge variant="primary" styles="bold" size="small">
                {unreadCount}
              </Badge>
            )}
          </div>
        </ListItem>
      </Popover.Trigger>
      <Popover.Content portal>
        <div className="w-[448px]">
          <NotificationList setOpen={store.setOpen} />
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
