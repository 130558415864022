import {
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedRootNode,
  SerializedTextNode,
} from "lexical";

// intentionally internal import to avoid circular dependency issues
import { richTextTextNodeRenderers } from "../../__utils/base/rich-text/lib/text-node-renderers";
import {
  createNodeRenderer,
  createNodeRenderers,
} from "./create-node-renderer";
import { Txt } from "./create-text-renderer";

export const renderTextRoot = createNodeRenderer<SerializedRootNode>(
  (_, children) => <Txt.Root>{children}</Txt.Root>
);

export const renderTextText = createNodeRenderer<SerializedTextNode>(
  ({ text }) => <Txt.Text>{text}</Txt.Text>
);

export const renderTextLineBreak = createNodeRenderer<SerializedLineBreakNode>(
  () => <Txt.LineBreak />
);

export const renderTextParagraph = createNodeRenderer<SerializedParagraphNode>(
  (_, children) =>
    children ? <Txt.Block>{children}</Txt.Block> : <Txt.LineBreak />
);

/** Text renderers for nodes that are built-in. */
export const baseTextNodeRenderers = createNodeRenderers({
  root: renderTextRoot,
  text: renderTextText,
  linebreak: renderTextLineBreak,
  paragraph: renderTextParagraph,
  ...richTextTextNodeRenderers,
});
