import _ from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { SerializedElement, serializeElement } from "./serialize";

/**
 * A hook that will watch the dom for changes and give us the latest dom elements whenever a change happens.
 * Note that the result is that of a
 * SerializedElement and not an element.
 *
 * This works across any shadow-dom elements provided they have the class `guide-shadow` so that we can identify them as shadow elements.
 *
 * This works across iframes with some limitations. The iframe must implement a receiver that will return back
 * results for `tour-query-selector` messages containing a SerializedElement. Additionally, to query an iframe we
 * must be explicit by prefixing the selector with `iframe#[id of iframe]` so that we can
 * send targeted messages to that iframe.
 */
export function useDomObserver(selector?: string) {
  const [id] = useState(uuid);
  const [target, setTarget] = useState<SerializedElement | null>(null);

  useEffect(() => {
    if (!selector) {
      return () => {};
    }

    // Handler for the iframe case
    if (selector.startsWith("iframe#") && _.includes(selector, " ")) {
      const [iframeSelector, ...selectors] = selector.split(" ");

      const iframe = document.querySelector(iframeSelector);

      const listener = (ev: MessageEvent) => {
        const { data } = ev;

        if (data.command === "tour-query-selector-result") {
          // Use the element that was returned by the iframe, but offset by the iframe positioning to get it's true positioning on the page.
          // TODO: this doesn't handle elements scrolled off screen. The fact that it's an iframe will potentially move the result onto the
          // screen when in reality it is not.
          if (data.id === id) {
            const iframeRect = iframe?.getBoundingClientRect() || {
              x: 0,
              y: 0,
            };
            setTarget(
              data.element
                ? {
                    ...data.element,
                    rect: {
                      ...data.element.rect,
                      x: data.element.rect.x + iframeRect.x,
                      y: data.element.rect.y + iframeRect.y,
                    },
                  }
                : null
            );
          }
        } else if (data.command === "tour-remote-ready") {
          // an iframe came online so re-send the query message as it won't have received it
          if (iframe && iframe instanceof HTMLIFrameElement) {
            iframe.contentWindow?.window.postMessage(
              {
                command: "tour-query-selector",
                selector: selectors.join(" "),
                id,
              },
              "*"
            );
          }
        }
      };

      window.addEventListener("message", listener);

      // Send a message to the iframe to tell it to start watching for this selector and response if it does.
      if (iframe && iframe instanceof HTMLIFrameElement) {
        iframe.contentWindow?.window.postMessage(
          {
            command: "tour-query-selector",
            selector: selectors.join(" "),
            id,
          },
          "*"
        );
      } else {
        setTarget(null);
      }

      return () => {
        // Clean up on the iframe side. By sending a `null` selector, we are telling the observer they should stop watching and
        // sending messages for any changes that this id asked for previously.
        if (iframe && iframe instanceof HTMLIFrameElement) {
          iframe.contentWindow?.window.postMessage(
            {
              command: "tour-query-selector",
              selector: null,
              id,
            },
            "*"
          );
        }
        // Remove listener
        window.removeEventListener("message", listener);
      };
    }

    // If we're not looking in an iframe we can handle everything with standard querySelector.
    const updateTarget = () => {
      const el = serializeElement(document.querySelector(selector));
      if (el) {
        setTarget(el);
      } else {
        // We need to search inside shadow doms for elements. There is no easy way to ask javascript for all shadow roots,
        // so instead we explicitly label them if we might be interested in them using `guide-shadow` class.
        // This will search inside any of those for the given selector. Note: this means that you
        // cannot have a selector work across boundaries. For example if you have an element #a with
        // a shadow root inside with a #b element inside that querying
        //
        document.querySelectorAll(".guide-shadow").forEach((container) => {
          const shadowElement = serializeElement(
            container?.shadowRoot?.querySelector(selector)
          );
          if (shadowElement) {
            setTarget(shadowElement);
          }
        });
      }
    };

    const observer = new MutationObserver(updateTarget);
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Call the update function immediately as the dom may already be stable.
    updateTarget();

    return () => observer.disconnect();
  }, [selector, id, setTarget]);

  return target ?? null;
}
