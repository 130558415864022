import React from "react";
import ReactDOM from "react-dom";

import { Arrow } from "./components/Arrow";
import { Dialog } from "./components/Dialog";
import { Highlighter } from "./components/Highlighter";
import { MessageBox } from "./components/MessageBox";
import { Narrator } from "./components/Narrator";
import { MessageBoxBaseProps } from "./components/RawMessageBox";
import { TourManager } from "./useTourManager";

export const TOUR_Z_INDICES = {
  HIGHLIGHTS: 1999999999,
  BACKDROP: 2000000000,
  CONTENT: 2000000001,
};

export function TourRenderer({ tour }: { tour: TourManager }) {
  const {
    isStarted,
    isReady,
    isFinished,
    isLastStage,
    currentStage,
    advance,
    dismiss,
    hasBeenShown,
    learnMoreUrl,
  } = tour;

  // Early return if tour isn't active
  if (!isStarted || !currentStage || isFinished || hasBeenShown) {
    return null;
  }

  const { instructions, arrows, highlights } = currentStage;

  const renderContent = () => {
    // If we're not ready just grey out the screen
    if (!isReady) {
      return (
        <>
          <Highlighter selectors={[]} />
          <div
            className="w-screen h-screen fixed top-0 left-0 pointer-events-none"
            style={{ zIndex: TOUR_Z_INDICES.BACKDROP }}
          />
        </>
      );
    }

    const commonMessageBoxProps: MessageBoxBaseProps = {
      image: instructions.image,
      ctaText: instructions.buttonText ?? (isLastStage ? "Finish" : "Next"),
      hideDismiss: instructions.hideDismiss,
      hideCTA: instructions.hideCTA,
      onCTAClick: advance,
      onDismiss: dismiss,
      learnMoreUrl,
    };

    return (
      <>
        <div
          className="w-screen h-screen fixed top-0 left-0 pointer-events-none"
          style={{ zIndex: TOUR_Z_INDICES.BACKDROP }}
        />

        {!instructions.target && (
          <>
            {instructions.dialogVariant === "narrator" ? (
              <Narrator {...commonMessageBoxProps}>
                {instructions.content}
              </Narrator>
            ) : (
              <Dialog {...commonMessageBoxProps}>{instructions.content}</Dialog>
            )}
          </>
        )}
        {instructions.target && (
          <MessageBox
            {...commonMessageBoxProps}
            selector={instructions.target}
            placement={instructions.placement}
          >
            {instructions.content}
          </MessageBox>
        )}
        {arrows?.map((arrow) => (
          <Arrow
            key={arrow.target}
            selector={arrow.target}
            placement={arrow.placement}
          />
        ))}
        {highlights && <Highlighter selectors={highlights} />}
      </>
    );
  };

  // Find the portal root element
  const tourRoot = document.getElementById("tour-root");

  // If portal root isn't available, render in place
  if (!tourRoot) {
    return renderContent();
  }

  // Render through portal to ensure proper stacking
  return ReactDOM.createPortal(renderContent(), tourRoot);
}
