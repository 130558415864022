import { useToast } from "@resource/atlas/toast/use-toast";
import type { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { compact, isEqual, omit } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { useQueryStringValue } from "utils/next";

import { ColumnDisplay, DefaultColumnConfig } from "./types";

/**
 * Syntax for specifying the values for use with the column show functionality as part of react table.
 * Ideally this should be part of the createColumnHelper function/types.
 */
type ToggleShowMetadata = {
  toggleShow?: {
    disabled?: boolean;
    default?: boolean;
  };
};
export function generateDefaultColumnConfig<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ColumnDef<any, unknown>[]
>(columns: T): DefaultColumnConfig[] {
  return compact(
    columns.map((column) => {
      // This is a safe cast assuming we keep all properties of ToggleShowMetadata optional.
      const meta = column.meta as ToggleShowMetadata | undefined | null;

      if (!column.id) {
        return null;
      }

      return {
        key: column.id!,
        label: column.header?.toString() ?? column.id!,
        default: meta?.toggleShow?.default ?? true,
        disabled: meta?.toggleShow?.disabled ?? false,
      };
    })
  );
}

type UsePersistedColumnSelectProps = {
  key: string;
  columns: DefaultColumnConfig[];
};

/**
 * Creates a column selection config that allows columns to be shown/hidden. The config is persisted to local storage so refreshing the page
 * will keep the same columns shown/hidden.
 */
export function usePersistedColumnSelect({
  key,
  columns: columnInput,
}: UsePersistedColumnSelectProps) {
  const [columns, setColumns] = useState<ColumnDisplay[]>([]);

  const localStorageKey = useMemo(
    () => `__guide_PersistedColumnSelect:${key}`,
    [key]
  );

  // Read in from local storage
  useEffect(() => {
    const stored = localStorage.getItem(localStorageKey);
    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        setColumns(
          columnInput
            .map((column) => {
              const persisted = parsed.find(
                (v: Pick<ColumnDisplay, "key" | "selected">) =>
                  v.key === column.key
              );

              return {
                ...column,
                disabled: column.disabled ?? false,
                selected: column.disabled
                  ? column.disabled
                  : persisted?.selected ?? column.default ?? false,
              };
            })
            // Sort by the ordering of the persisted columns
            .sort((a, b) => {
              const indexA = parsed.findIndex(
                (v: { key: string }) => v.key === a.key
              );
              const indexB = parsed.findIndex(
                (v: { key: string }) => v.key === b.key
              );
              // If the column is not found in localStorage, put it at the end
              return indexA === -1 ? 999 : indexA - indexB;
            })
        );
      } catch (err) {
        console.warn("Error parsing persisted column select", err);
        localStorage.removeItem(localStorageKey);
      }
    } else {
      setColumns(
        columnInput.map((column) => ({
          ...column,
          disabled: column.disabled ?? false,
          selected: column.disabled ? column.disabled : column.default ?? false,
        }))
      );
    }
  }, [localStorageKey, columnInput]);

  // Save out to local storage
  useEffect(() => {
    const toSave = columns.map((v) => ({
      key: v.key,
      selected: v.selected,
    }));

    localStorage.setItem(localStorageKey, JSON.stringify(toSave));
  }, [localStorageKey, columns]);

  const toggleColumn = useCallback((columnKey: string) => {
    setColumns((prev) => {
      return prev.map((v) => {
        if (v.key === columnKey) {
          return {
            ...v,
            selected: !v.selected,
          };
        }

        return v;
      });
    });
  }, []);

  const reorderColumns = useCallback((newOrder: ColumnDisplay[]) => {
    setColumns((prev) => {
      return compact(
        newOrder.map((column) => prev.find((v) => v.key === column.key))
      );
    });
  }, []);
  return {
    columns,
    toggleColumn,
    reorderColumns,
  };
}

/**
 * A hook to provide filters that are persisted in the URL.
 */
export function usePersistedFilters<
  T extends Record<string, unknown>
>(defaults?: {
  filters?: T;
  resetValue?: T;
  deprecatedFilterKeys?: { key: string; message: string }[];
}) {
  const [defaultFilters] = useState(defaults?.filters ?? {});
  const { sendToast } = useToast();

  const globalFilter = useQueryStringValue("globalFilter");
  const rawFilters = useQueryStringValue("filters");
  const router = useRouter();
  const { searchTerm, setSearchTerm, debouncedTerm } =
    useDebouncedSearch(globalFilter);
  const [filters, setFilters] = useState<T>({} as T);
  useEffect(() => {
    if (defaults?.deprecatedFilterKeys) {
      const deprecatedKeys = defaults.deprecatedFilterKeys;
      const parsedFilters = rawFilters ? (JSON.parse(rawFilters) as T) : {};

      for (const { key, message } of deprecatedKeys) {
        if (key in parsedFilters) {
          router.replace({
            pathname: router.pathname,
            query: {
              ...router.query,
              filters: JSON.stringify(omit(parsedFilters, key)),
            },
          });
          sendToast(message, { variant: "error" });
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilter = useCallback(
    async <K extends keyof T>(key: K, value: T[K]) => {
      const newFilters = { ...(filters ?? {}), [key]: value };
      await router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          filters: JSON.stringify(newFilters),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const resetFilters = useCallback(
    async (props?: { exclude?: string[] }) => {
      const current = filters;
      const override = omit(
        defaults?.resetValue ?? defaultFilters,
        props?.exclude ?? []
      );

      const newFilters = { ...current, ...override };
      await router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          filters: JSON.stringify(newFilters),
        },
      });
    },
    [defaultFilters, defaults?.resetValue, filters, router]
  );

  useEffect(() => {
    const parsedFilters = rawFilters
      ? (JSON.parse(rawFilters) as T)
      : ({} as T);

    const newFilters = { ...defaultFilters, ...parsedFilters };

    // If the default filters cause a change, we want to put them into the URL
    if (
      !isEqual(newFilters, parsedFilters) &&
      JSON.stringify(newFilters) !== rawFilters
    ) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          filters: JSON.stringify(newFilters),
        },
      });
    }

    setFilters({ ...defaultFilters, ...parsedFilters });
  }, [rawFilters, defaultFilters, router]);

  // Ensure we sync the search term up to the URL
  useEffect(() => {
    if (globalFilter !== debouncedTerm) {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          globalFilter: debouncedTerm,
        },
      });
    }
  }, [debouncedTerm, globalFilter, router]);

  return {
    searchTerm,
    setSearchTerm,
    debouncedTerm,
    filters,
    setFilter,
    resetFilters,
  };
}

/**
 * A hook to provide grouping that is persisted in the URL.
 */
export function usePersistedGrouping<
  T extends Record<string, unknown>
>(options?: { grouping: T }) {
  const router = useRouter();
  const [defaultGrouping] = useState(options?.grouping ?? {});

  const rawGrouping = useQueryStringValue("grouping");
  const [grouping, setGroupingState] = useState<T>({} as T);

  const setGrouping = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      const newGroupings = { ...grouping, [key]: value };
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          grouping: JSON.stringify(newGroupings),
        },
      });
    },
    [grouping, router]
  );

  useEffect(() => {
    const parsedGrouping = rawGrouping
      ? (JSON.parse(rawGrouping) as T)
      : ({} as T);

    const newGrouping = { ...defaultGrouping, ...parsedGrouping };

    if (
      !isEqual(newGrouping, parsedGrouping) &&
      JSON.stringify(newGrouping) !== rawGrouping
    ) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          grouping: JSON.stringify(newGrouping),
        },
      });
    }

    setGroupingState(newGrouping);
  }, [rawGrouping, defaultGrouping, router]);

  return { grouping, setGrouping };
}

export function useReadPersistedFilter<T>(name: string) {
  const rawFilters = useQueryStringValue("filters");
  const router = useRouter();
  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    const parsedFilters = rawFilters
      ? (JSON.parse(rawFilters) as { [key: string]: T | null })
      : ({} as { [key: string]: T | null });

    setValue((parsedFilters[name] as T) ?? null);
  }, [rawFilters, router, name]);

  return value;
}

export function usePaginationUrlParams() {
  const router = useRouter();
  const pageStr = useQueryStringValue("page");
  const pageSizeStr = useQueryStringValue("pageSize");

  const page = useMemo(() => {
    const num = parseInt(pageStr, 10);
    if (Number.isNaN(num) || num < 0) {
      return 0;
    }

    return num;
  }, [pageStr]);
  const pageSize = useMemo(() => {
    const num = parseInt(pageSizeStr, 10);
    if (Number.isNaN(num) || num < 1 || num > 100) {
      return 25;
    }

    return num;
  }, [pageSizeStr]);

  const setPage = useCallback(
    (newPage: number) => {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          page: newPage,
        },
      });
    },
    [router]
  );

  const setPageSize = useCallback(
    (newSize: number) => {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          pageSize: newSize,
        },
      });
    },
    [router]
  );

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
  };
}

export function useLocalPagination({
  initialPage = 0,
  initialPageSize = 25,
}: { initialPage?: number; initialPageSize?: number } = {}) {
  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
  };
}

type PaginationProps = {
  total: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
};

export function usePaginationUtils({
  total,
  page,
  pageSize,
  setPage,
}: PaginationProps) {
  const canNextPage = useMemo(
    () => page + 1 < Math.ceil(total / pageSize),
    [page, pageSize, total]
  );
  const canPrevPage = useMemo(() => page > 0, [page]);

  const nextPage = useCallback(() => {
    if (canNextPage) {
      setPage(page + 1);
    }
  }, [canNextPage, page, setPage]);
  const prevPage = useCallback(() => {
    if (canPrevPage) {
      setPage(page - 1);
    }
  }, [canPrevPage, page, setPage]);

  const usePaginationResetOnFilterChange = useCallback(
    ({
      numResults,
      resultsLoading,
    }: {
      numResults: number;
      resultsLoading: boolean;
    }) => {
      if (page !== 0 && numResults === 0 && !resultsLoading) {
        setPage(0);
      }
    },
    [page, setPage]
  );

  return {
    canNextPage,
    canPrevPage,
    nextPage,
    prevPage,
    usePaginationResetOnFilterChange,
  };
}

export function useVisibleColumns<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ColumnDef<any, unknown>[]
>({
  columns,
  toggledColumns,
}: {
  columns: T;
  toggledColumns: ColumnDisplay[];
}): VisibilityState {
  return useMemo(() => {
    const base = columns.reduce((acc, column) => {
      if (!column.id) {
        return acc;
      }

      return {
        ...acc,
        [column.id]: false,
      };
    }, {});

    return toggledColumns.reduce((acc, column) => {
      return {
        ...acc,
        [column.key]: column.selected,
      };
    }, base);
  }, [columns, toggledColumns]);
}

/**
 * Assuming the orderBy includes all sortable columns
 * Returns whether the column is sortable
 */
export function getIsSortableColumnFromOrderBy({
  orderBy,
  header,
}: {
  orderBy?: { field: string }[] | null;
  header: { id: string };
}): boolean {
  return orderBy?.some((v) => v.field === header.id) ?? false;
}

/**
 * Check if this column is the primary sort column by check first value on the orderBy
 * Hacking around since we don't support UI for secondary sort
 * So only show sort icon on the primary sort
 */
export function getPrimarySortForColumnFromOrderBy({
  orderBy,
  header,
}: {
  orderBy?: { field: string; sort: "asc" | "desc" }[] | null;
  header: { id: string };
}) {
  const primarySort = orderBy?.[0];

  if (!primarySort || primarySort.field !== header.id) {
    return undefined;
  }

  return primarySort.sort;
}
