import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { useRender } from "@resource/atlas/content-editor/renderer";
import { themeContainerClass } from "@resource/atlas/content-editor/theme";
import clsx from "clsx";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { renderComment } from "shared/comments/content-editor/renderers";

type CommentRendererProps = {
  content: unknown;
} & ComponentPropsWithoutRef<"div">;

export const CommentRenderer = forwardRef<HTMLDivElement, CommentRendererProps>(
  ({ content, ...props }, ref) => {
    const renderedContent = useRender(
      renderComment,
      content as AtlasContentEditorSerializedState
    );

    return (
      <div
        {...props}
        ref={ref}
        className={clsx(props.className, themeContainerClass)}
      >
        {renderedContent}
      </div>
    );
  }
);
