import { createMentionNodeRenderer } from "@resource/atlas/content-editor/mentions";
import {
  baseNodeRenderers,
  baseTextNodeRenderers,
  createRenderer,
  createTextRenderer,
} from "@resource/atlas/content-editor/renderer";

import { createMentionEmailRenderer } from "./mentions/email";
import { createMentionTextRenderer } from "./mentions/text";

export const renderComment = createRenderer({
  nodeRenderers: {
    ...baseNodeRenderers,
    mention: createMentionNodeRenderer(),
  },
});

export const renderCommentForEmail = createRenderer({
  nodeRenderers: {
    ...baseNodeRenderers,
    mention: createMentionEmailRenderer(),
  },
});

export const renderCommentForText = createTextRenderer({
  nodeRenderers: {
    ...baseTextNodeRenderers,
    mention: createMentionTextRenderer(),
  },
});
