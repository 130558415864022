import { SerializedMentionNode } from "@resource/atlas/content-editor/mentions";
import {
  createNodeRenderer,
  Txt,
} from "@resource/atlas/content-editor/renderer";

export const createMentionTextRenderer = () =>
  createNodeRenderer<SerializedMentionNode>(({ value }) => {
    return <Txt.Text>{value.name}</Txt.Text>;
  });
