import _ from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { SerializedElement, serializeElement } from "./serialize";

/**
 * Similar to useDomObserver.tsx but takes in a list of selectors instead of a single selector and returns an array back. See useDomObserver
 * for more technical details.
 *
 * Note: we could likely share more here with useDomObserver by using generics.
 */
export function useDomObservers(
  selectors: string[]
): (SerializedElement | null)[] {
  const [id] = useState(uuid);
  // Maintain a map of selectors => results as our results will come back async in the iframe case.
  const [targets, setTargets] = useState<
    Record<string, SerializedElement | null>
  >({});

  useEffect(() => {
    // We will need a different handler per iframe + non-iframe cases. This will divide our selectors so they are correctly grouped
    // together by iframe id (or lack of)
    const groups = _.groupBy(selectors, (v) =>
      v.startsWith("iframe#") && _.includes(v, " ") ? v.split(" ")[0] : ""
    );

    const cleanupFns = Object.keys(groups).map((key) => {
      const groupSelectors = groups[key];

      // Handler for the iframe case
      if (key.startsWith("iframe")) {
        const iframe = document.querySelector(key);
        const listener = (ev: MessageEvent) => {
          const { data } = ev;

          if (data.command === "tour-query-selector-result") {
            // Use the element that was returned by the iframe, but offset by the iframe positioning to get it's true positioning
            // on the page.
            // TODO: this doesn't handle elements scrolled off screen. The fact that it's an iframe will potentially move the result
            // onto the screen when in reality it is not.
            if (data.id === id) {
              const iframeRect = iframe?.getBoundingClientRect() || {
                x: 0,
                y: 0,
              };
              const correctedTarget = data.element
                ? {
                    ...data.element,
                    rect: {
                      ...data.element.rect,
                      x: data.element.rect.x + iframeRect.x,
                      y: data.element.rect.y + iframeRect.y,
                    },
                  }
                : null;

              setTargets((t) => ({
                ...t,
                [`${key} ${data.selector}`]: correctedTarget,
              }));
            }
          } else if (data.command === "tour-remote-ready") {
            // an iframe came online so re-send the query message as it won't have received it
            if (iframe && iframe instanceof HTMLIFrameElement) {
              iframe.contentWindow?.window.postMessage(
                {
                  command: "tour-query-selector",
                  selector: groupSelectors.map((fullSelector) => {
                    const [, ...selector] = fullSelector.split(" ");
                    return selector.join(" ");
                  }),
                  id,
                },
                "*"
              );
            }
          }
        };

        window.addEventListener("message", listener);

        // Send a message to the iframe to tell it to start watching for this selector and response if it does.
        if (iframe && iframe instanceof HTMLIFrameElement) {
          iframe.contentWindow?.window.postMessage(
            {
              command: "tour-query-selector",
              selector: groupSelectors.map((fullSelector) => {
                const [, ...selector] = fullSelector.split(" ");
                return selector.join(" ");
              }),
              id,
            },
            "*"
          );
        }

        return () => {
          // Clean up on the iframe side. By sending a `null` selector, we are telling the observer they should stop watching and sending
          // messages for any changes changes that this id asked for previously.
          if (iframe && iframe instanceof HTMLIFrameElement) {
            iframe.contentWindow?.window.postMessage(
              {
                command: "tour-query-selector",
                selector: null,
                id,
              },
              "*"
            );
          }
          // Remove listener
          window.removeEventListener("message", listener);
        };
      }

      // If we're not looking in an iframe we can handle everything with standard querySelector.
      const updateTargets = () => {
        const result: Record<string, SerializedElement | null> = {};
        groupSelectors.forEach((selector) => {
          const element = serializeElement(document.querySelector(selector));
          result[selector] = element;

          if (!element) {
            // We need to search inside shadow doms for elements. There is no easy way to ask javascript for all shadow roots,
            // so instead we explicitly label them if we might be interested in them using `guide-shadow` class. This will search
            // inside any of those for the given selector. Note: this means that you cannot have a selector work across boundaries.
            // For example if you have an element #a with a shadow root inside with a #b element inside that querying for #a #b
            // will not return a result. You must just query for #b.
            document.querySelectorAll(".guide-shadow").forEach((container) => {
              const shadowElement = serializeElement(
                container?.shadowRoot?.querySelector(selector)
              );
              if (shadowElement) {
                result[selector] = shadowElement;
              }
            });
          }
        });
        setTargets(result);
      };

      const observer = new MutationObserver(updateTargets);
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      // Call the update function immediately as the dom may already be stable.
      updateTargets();

      return () => observer.disconnect();
    });

    // We potentially have mutliple listeners to remove (e.g. iframe + local dom observer) so should call them all
    return () => cleanupFns.forEach((fn) => fn());
  }, [selectors, id, setTargets]);

  return selectors.map((selector) => targets[selector] || null);
}
