import { Tab, TabList, TabPanel, TabRoot } from "./Tabs";
import {
  type TabListProps,
  type TabPanelProps,
  type TabProps,
  type TabRootProps,
} from "./Tabs";

export const Tabs = {
  Root: TabRoot,
  List: TabList,
  Panel: TabPanel,
  Tab,
};

export type {
  TabListProps as AtlasTabListProps,
  TabPanelProps as AtlasTabPanelProps,
  TabProps as AtlasTabProps,
  TabRootProps as AtlasTabRootProps,
};
