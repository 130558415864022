import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import { atlasCheck } from "../../icons";
import type { ButtonProps } from "../button-v2";
import { getButtonStyles, getIconStyles } from "../button-v2/utils";
import { Icon } from "../icon/Icon";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

export interface MenuRootProps extends Ariakit.MenuProviderProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children: React.ReactNode;
}

export interface MenuButtonProps
  extends Omit<Ariakit.MenuButtonProps, keyof ButtonProps>,
    ButtonProps {
  children?: React.ReactNode;
  className?: string;
}

export interface MenuProps extends Ariakit.MenuProps {
  children: React.ReactNode;
  className?: string;
}

export type MenuSeparatorProps = Ariakit.MenuSeparatorProps;

export interface MenuGroupProps extends Ariakit.MenuGroupProps {
  label?: React.ReactNode;
  leadingContent?: React.ReactNode;
}

export interface MenuItemProps extends Ariakit.MenuItemProps {
  size?: "compact" | "default" | "open";
  isSelected?: boolean;
  isSelectable?: boolean;
  leadingContent?: React.ReactNode;
  trailingContent?: React.ReactNode;
  color?: string;
}

export function MenuRoot(props: MenuRootProps) {
  const { children, store, ...rest } = props;

  return (
    <Ariakit.MenuProvider store={store} {...rest}>
      {children}
    </Ariakit.MenuProvider>
  );
}

export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      variant = "default",
      size = "md",
      isGhost = false,
      isDisabled = false,
      isLoading = false,
      icon,
      iconPosition = "left",
      ...rest
    } = props;

    const menu = Ariakit.useMenuContext();
    const state = Ariakit.useStoreState(menu);

    if (!menu) {
      throw new Error("MenuButton must be wrapped in MenuRoot");
    }

    return (
      <Ariakit.MenuButton
        ref={ref}
        store={menu}
        className={clsx(
          getButtonStyles({
            variant,
            size,
            isGhost,
            isDisabled,
            isLoading,
          }),
          state?.open &&
            "bg-purple-50 text-purple-500 hover:bg-purple-50 hover:text-purple-500",
          className
        )}
        disabled={isDisabled || isLoading}
        {...rest}
      >
        {isLoading && (
          <LoadingIndicator
            size="small"
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        )}
        <span
          className={clsx("flex items-center gap-2", isLoading && "invisible")}
        >
          {icon && iconPosition === "left" && (
            <Icon className={getIconStyles(size)} content={icon} />
          )}
          {children}
          {icon && iconPosition === "right" && (
            <Icon className={getIconStyles(size)} content={icon} />
          )}
        </span>
      </Ariakit.MenuButton>
    );
  }
);

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;
    const menu = Ariakit.useMenuContext();

    if (!menu) {
      throw new Error("Menu must be wrapped in MenuRoot");
    }

    return (
      <Ariakit.Menu
        ref={ref}
        store={menu}
        portal
        gutter={4}
        shift={-4}
        className={clsx(
          "rounded-md shadow-8 p-2",
          "z-[50] flex flex-col max-h-[300px] overflow-auto overscroll-contain",
          "bg-white text-dark",
          className
        )}
        {...rest}
      >
        {children}
      </Ariakit.Menu>
    );
  }
);

export const MenuSeparator = React.forwardRef<
  HTMLHRElement,
  MenuSeparatorProps
>((props, ref) => {
  const menu = Ariakit.useMenuContext();

  if (!menu) {
    throw new Error("MenuSeparator must be wrapped in MenuRoot");
  }

  return (
    <Ariakit.MenuSeparator
      ref={ref}
      store={menu}
      {...props}
      className={clsx(
        "h-0 border-t border-gray-border my-2 -mx-2",
        props.className
      )}
    />
  );
});

export const MenuGroup = React.forwardRef<HTMLDivElement, MenuGroupProps>(
  ({ label, leadingContent, ...props }, ref) => {
    const menu = Ariakit.useMenuContext();

    if (!menu) {
      throw new Error("MenuGroup must be wrapped in MenuRoot");
    }

    return (
      <Ariakit.MenuGroup
        ref={ref}
        store={menu}
        {...props}
        className={clsx(props.className)}
      >
        {label && (
          <Ariakit.MenuGroupLabel
            store={menu}
            className={clsx(
              "flex items-center gap-2 text-h5 text-subtle py-1.5 px-3"
            )}
          >
            {leadingContent}
            {label}
          </Ariakit.MenuGroupLabel>
        )}
        {props.children}
      </Ariakit.MenuGroup>
    );
  }
);

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  (props, ref) => {
    const {
      children,
      size = "default",
      isSelected,
      isSelectable,
      leadingContent,
      trailingContent,
      color,
      className,
      ...rest
    } = props;

    const menu = Ariakit.useMenuContext();

    if (!menu) {
      throw new Error("MenuItem must be wrapped in MenuRoot");
    }

    const heightClass = {
      compact: "py-1.5 h-8",
      default: "py-2.5 h-9",
      open: "py-3 h-10",
    }[size];

    return (
      <Ariakit.MenuItem
        ref={ref}
        store={menu}
        {...rest}
        className={clsx(
          "w-full rounded-[.375rem] bg-white",
          "flex text-body-md text-dark",
          heightClass,
          // Interactive states
          "cursor-pointer select-none",
          // Hover/Focus states
          "hover:bg-light-gray-200 focus:bg-light-gray-200",
          // Active states
          "data-[active-item]:bg-light-gray-500",
          // Pressed state
          "active:hover:bg-light-gray-500",
          "focus:outline-none",
          className
        )}
      >
        {isSelectable && (
          <div className={clsx("px-2", !isSelected && "opacity-0")}>
            <Icon content={atlasCheck} />
          </div>
        )}
        <div
          className={clsx(
            "flex-grow h-full flex items-center gap-2 overflow-hidden",
            isSelectable ? "pr-3" : "px-3"
          )}
        >
          {leadingContent && (
            <div className="flex-shrink-0">
              {color && (
                <div
                  className={clsx("w-2 h-2 rounded-full mr-2", `bg-[${color}]`)}
                />
              )}
              {leadingContent}
            </div>
          )}
          <div className="overflow-hidden flex-grow">
            <p className="truncate">{children}</p>
          </div>
          {trailingContent && (
            <div className="flex-shrink-0">{trailingContent}</div>
          )}
        </div>
      </Ariakit.MenuItem>
    );
  }
);

MenuRoot.displayName = "MenuRoot";
MenuButton.displayName = "MenuButton";
Menu.displayName = "Menu";
