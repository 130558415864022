import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface DialogContextType {
  isAnyDialogOpen: boolean;
  incrementDialogCount: () => void;
  decrementDialogCount: () => void;
  isSheetVariantFitContent: boolean;
  setIsSheetVariantFitContent: (setIsSheetVariantFitContent: boolean) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);
export const useDialogContext = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialogContext must be used within a DialogProvider");
  }
  return context;
};

export const useOptionalDialogContext = (): DialogContextType | undefined => {
  const context = useContext(DialogContext);
  return context;
};

export function DialogProvider({ children }: { children: ReactNode }) {
  const [dialogCount, setDialogCount] = useState(0);
  const [isSheetVariantFitContent, setIsSheetVariantFitContent] =
    useState(false);

  const incrementDialogCount = useCallback(
    () => setDialogCount((c) => c + 1),
    []
  );
  const decrementDialogCount = useCallback(
    () => setDialogCount((c) => Math.max(0, c - 1)),
    []
  );

  const isAnyDialogOpen = useMemo(() => dialogCount > 0, [dialogCount]);

  return (
    <DialogContext.Provider
      value={{
        isAnyDialogOpen,
        incrementDialogCount,
        decrementDialogCount,
        isSheetVariantFitContent,
        setIsSheetVariantFitContent,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
}
