/* eslint-disable react/jsx-no-target-blank */
import { SerializedAutoLinkNode, SerializedLinkNode } from "@lexical/link";
import type { SerializedListItemNode, SerializedListNode } from "@lexical/list";
import type { SerializedHorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import type {
  SerializedHeadingNode,
  SerializedQuoteNode,
} from "@lexical/rich-text";
import clsx from "clsx";

// intentionally internal import to avoid circular dependency issues
import {
  createNodeRenderer,
  createNodeRenderers,
} from "../../../../renderer/__lib/create-node-renderer";

export const renderHeading = createNodeRenderer<SerializedHeadingNode>(
  ({ tag: Heading }, children, { theme }) => (
    <Heading className={theme?.heading?.[Heading]}>{children}</Heading>
  )
);

export const renderList = createNodeRenderer<SerializedListNode>(
  ({ tag: List, start }, children, { theme }) => (
    <List
      start={start !== 1 ? start : undefined}
      className={theme?.list?.[List]}
    >
      {children}
    </List>
  )
);

export const renderLink = createNodeRenderer<
  SerializedLinkNode | SerializedAutoLinkNode
>(({ url, rel }, children, opts) => {
  const { theme } = opts;
  return (
    <a
      href={url}
      rel={rel ?? "noreferrer"}
      target="_blank"
      className={theme?.link}
    >
      {children}
    </a>
  );
});

export const renderListItem = createNodeRenderer<SerializedListItemNode>(
  (
    _,
    children,
    { theme, childIndex, list: { start = 1, isListNestedItem, indexDrift } }
  ) => {
    if (childIndex == null) throw new Error("List item can't be root");
    if (indexDrift == null) throw new Error("Missing index drift context");
    return (
      <li
        value={1 + childIndex - indexDrift + start}
        className={clsx(
          theme?.list?.listitem,
          isListNestedItem && theme?.list?.nested?.listitem
        )}
      >
        {children}
      </li>
    );
  }
);

export const renderHorizontalRule =
  createNodeRenderer<SerializedHorizontalRuleNode>(() => <hr />);

export const renderQuote = createNodeRenderer<SerializedQuoteNode>(
  (_, children, { theme }) => (
    <blockquote className={theme?.quote}>{children}</blockquote>
  )
);

/** Renderers for nodes that are part of the rich text functionality of content editor. */
export const richTextNodeRenderers = createNodeRenderers({
  heading: renderHeading,
  link: renderLink,
  autolink: renderLink,
  list: renderList,
  listitem: renderListItem,
  horizontalrule: renderHorizontalRule,
  quote: renderQuote,
});

// TODO:
// - nested list/listitem classNames
// - list depth classNames
