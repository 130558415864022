import { CommentRenderer } from "client/comments/content-editor/CommentRenderer";
import clsx from "clsx";
import React from "react";

import { BaseNotificationDisplayProps } from "./BaseNotificationDisplay";

export function CommentNotificationDisplay({
  notification,
  className,
}: BaseNotificationDisplayProps) {
  const { actor, meta } = notification;

  return (
    <div className={clsx(className, "space-y-.5")}>
      <p className="text-body-md text-subtle">{actor?.name} commented:</p>
      <CommentRenderer content={meta?.message} />
    </div>
  );
}
