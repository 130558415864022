/* eslint-disable import/prefer-default-export */
import "./MentionNode.sass";

import { createComponentUtils } from "../../../__utils/atlas";
import { createNodeRenderer } from "../../renderer";
import type { SerializedMentionNode } from "./MentionNode";
import { MentionValue } from "./types";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Mention";

const { el } = createComponentUtils(COMPONENT_NAME);

type MentionProps = {
  value: MentionValue;
};

function Mention({ value: nodeValue }: MentionProps) {
  return <div className={el`renderer`}>@{nodeValue.name}</div>;
}

export const createMentionNodeRenderer = () =>
  createNodeRenderer<SerializedMentionNode>(({ value }) => {
    return <Mention value={value} />;
  });
