import Image from "next/image";
import React from "react";

interface NoNotificationsProps {
  title?: string;
  subtitle?: string;
}

export function NoNotifications({
  title = "You don't have any notifications",
  subtitle = "No notifications at the moment. We'll keep you posted!",
}: NoNotificationsProps) {
  return (
    <div className="flex flex-col gap-6 items-center justify-center py-12 text-center">
      <Image
        src="/images/no-notifications.svg"
        alt="No notifications"
        width={364}
        height={108}
      />
      <div className="space-y-2">
        <h4 className="text-h4 text-dark">{title}</h4>
        <p className="text-body-md text-subtle">{subtitle}</p>
      </div>
    </div>
  );
}
