import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasClose } from "@resource/atlas/icons";
import { Button } from "ariakit";
import clsx from "clsx";
import React, { ComponentPropsWithoutRef } from "react";

import { TOUR_Z_INDICES } from "../TourRenderer";

type NarratorProps = ComponentPropsWithoutRef<"div"> & {
  onNext?: () => unknown;
  image?: string;
  canClose?: boolean;
  buttonText?: string;
  hideNextButton?: boolean;
};

export function Dialog({
  children,
  className,
  canClose,
  image,
  onNext,
  buttonText,
  hideNextButton,
  ...props
}: NarratorProps) {
  return (
    <div
      className={clsx(
        `w-screen h-screen fixed top-0 left-0 flex items-center justify-center`
      )}
      style={{
        zIndex: TOUR_Z_INDICES.BACKDROP,
      }}
    >
      <div className="w-full h-full bg-black fixed top-0 left-0 opacity-50 pointer-events-none" />
      <div
        className={clsx(
          `w-[35rem] rounded-md bg-white drop-shadow-xl text-body-md z-[${TOUR_Z_INDICES.CONTENT}]`,
          className
        )}
        style={{
          ...props.style,
          zIndex: TOUR_Z_INDICES.CONTENT,
        }}
        {...props}
      >
        {image && (
          <div
            className={clsx(
              "h-[23.25rem] rounded-t-md bg-cover w-full p-1 flex items-start justify-end relative z-1"
            )}
            style={{ backgroundImage: `url('${image}')` }}
          >
            {canClose && <ButtonGroup icon={atlasClose} />}
          </div>
        )}

        <div className="px-6 py-6">{children}</div>

        {!hideNextButton && onNext && (
          <div className="px-4 pb-4 flex justify-end">
            {/* TODO: hover + focus states */}
            <Button
              onClick={onNext}
              className="bg-dark-gray-500 hover:bg-dark-gray-400 px-2.5 py-1.5 rounded-md text-body-md-heavy text-white"
            >
              {buttonText || "Next"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
