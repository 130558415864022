/* eslint-disable no-bitwise */
import type {
  EditorThemeClasses,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedRootNode,
  SerializedTextNode,
} from "lexical";
import type { CSSProperties } from "react";

// intentionally internal import to avoid circular dependency issues
import { richTextNodeRenderers } from "../../__utils/base/rich-text/lib/node-renderers";
import {
  createNodeRenderer,
  createNodeRenderers,
} from "./create-node-renderer";

// base node renderers
// -------------------

export const renderRoot = createNodeRenderer<SerializedRootNode>(
  (_, children, { theme }) => <div className={theme?.root}>{children}</div>
);

const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;

function getTextClassName(format: number, theme?: EditorThemeClasses) {
  const classes = theme?.text?.base ? [theme?.text.base] : [];

  function withClass(flag: number, className: string | undefined) {
    if (className && format & flag) classes.push(className);
  }

  // TODO: document special case
  // TODO: figure out if the normal classNames still need to be applied or not?
  if (
    theme?.text?.underlineStrikethrough &&
    format & IS_STRIKETHROUGH &&
    format & IS_UNDERLINE
  )
    classes.push(theme.text?.underlineStrikethrough);

  withClass(IS_BOLD, theme?.text?.bold);
  withClass(IS_ITALIC, theme?.text?.italic);
  withClass(IS_STRIKETHROUGH, theme?.text?.strikethrough);
  withClass(IS_UNDERLINE, theme?.text?.underline);
  withClass(IS_CODE, theme?.code);
  withClass(IS_SUBSCRIPT, theme?.text?.subscript);
  withClass(IS_SUPERSCRIPT, theme?.text?.superscript);

  return classes.length > 0 ? classes.join(" ") : undefined;
}

function getTextOuterTag(format: number) {
  if (format & IS_CODE) return "code";
  if (format & IS_SUBSCRIPT) return "sub";
  if (format & IS_SUPERSCRIPT) return "sup";
  return undefined;
}

function getTextInnerTag(format: number) {
  if (format & IS_BOLD) return "strong";
  if (format & IS_ITALIC) return "em";
  return "span";
}

export const renderText = createNodeRenderer<SerializedTextNode>(
  ({ text, format, style: nodeStyle }, _, { theme }) => {
    const OuterTag = getTextOuterTag(format);
    const InnerTag = getTextInnerTag(format);

    const style =
      nodeStyle !== "" ? ({ cssText: nodeStyle } as CSSProperties) : undefined;
    const outerProps = { ...(style && { style }) };
    const className = getTextClassName(format, theme);

    const innerElement = (
      <InnerTag {...(!OuterTag && outerProps)} className={className}>
        {text}
      </InnerTag>
    );

    return OuterTag ? (
      <OuterTag {...outerProps}>{innerElement}</OuterTag>
    ) : (
      innerElement
    );
  }
);

export const renderLineBreak = createNodeRenderer<SerializedLineBreakNode>(
  () => <br />
);

export const renderParagraph = createNodeRenderer<SerializedParagraphNode>(
  ({ format, direction }, children, { theme }) => (
    <p
      className={theme?.paragraph}
      style={format ? { textAlign: format } : undefined}
      dir={direction ?? undefined}
    >
      {children ?? <br />}
    </p>
  )
);

/** Renderers for nodes that are built-in. */
export const baseNodeRenderers = createNodeRenderers({
  root: renderRoot,
  text: renderText,
  linebreak: renderLineBreak,
  paragraph: renderParagraph,
  ...richTextNodeRenderers,
});
