import { useAuthContext } from "auth/context";
import { useFlags } from "client/lib/launchdarkly";
import { useFlagsLoading } from "client/utils/FlagsLoadingContext";
import FeatureFlags from "generated/FeatureFlags";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

export function useNeedGoogleCalendarPermissions() {
  const { user, hasFeatureEnabled, loading: loadingAuth } = useAuthContext();

  const {
    [FeatureFlags.WATCH_GOOGLE_USER_DEACTIVATION]: watchGoogleUsersOrgIdsList,
    [FeatureFlags.REQUIRE_GOOGLE_MEET_API]: requireGoogleMeetApi,
  } = useFlags();

  const { loading } = useFlagsLoading();

  const userSyncEnabledForOrg = (watchGoogleUsersOrgIdsList ?? [])?.includes(
    user?.currentOrganization?.id
  );

  return useMemo(() => {
    if (loading || loadingAuth) {
      return {
        loading: true,
      };
    }

    const hasCorrectGoogleMeetApiPermissions =
      !requireGoogleMeetApi || user?.canAccessGoogleMeet;
    const isGoogleSyncUser =
      user?.id ===
      user?.currentOrganization?.defaultGoogleUserMembership?.user.id;
    const hasPermissions =
      user?.canAccessCalendar &&
      hasCorrectGoogleMeetApiPermissions &&
      (!isGoogleSyncUser ||
        !userSyncEnabledForOrg ||
        user?.canAccessGoogleUsers);

    return {
      loading: false,
      isGoogleSyncUser,
      userSyncEnabledForOrg,
      hasPermissions:
        !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
        hasPermissions,
      requireGoogleMeet: requireGoogleMeetApi,
    };
  }, [
    loading,
    loadingAuth,
    requireGoogleMeetApi,
    user?.canAccessGoogleMeet,
    user?.id,
    user?.currentOrganization?.defaultGoogleUserMembership?.user.id,
    user?.canAccessCalendar,
    user?.canAccessGoogleUsers,
    userSyncEnabledForOrg,
    hasFeatureEnabled,
  ]);
}
