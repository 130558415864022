import { useDialogContext } from "@resource/atlas/dialog/DialogContext";
import { compact, debounce } from "lodash";
import { useEffect, useRef } from "react";

type BoundingRect = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export const getRects = (
  elements: NodeListOf<Element>,
  matchSelector?: string
) => {
  return compact(
    Array.from(elements).map((element: Element) => {
      if (matchSelector && !element.matches(matchSelector)) {
        return null;
      }
      const rect = element.getBoundingClientRect();
      const { left, right, top, bottom } = rect;
      return { left, right, top, bottom };
    })
  );
};

export const observeElementForResize = (
  element: Element,
  observer: ResizeObserver
) => {
  observer.observe(element);
};

export const observeElementsForResize = (
  elements: NodeListOf<Element>,
  observer: ResizeObserver,
  matchSelector?: string
) => {
  elements.forEach((element) => {
    if (matchSelector && element.matches(matchSelector)) {
      observeElementForResize(element, observer);
    }
  });
};

const observeElementsForAttributeMutation = (
  elements: NodeListOf<Element>,
  observer: MutationObserver,
  attributes: string[]
) => {
  elements.forEach((element) => {
    observer.observe(element, {
      attributes: true,
      attributeFilter: attributes,
    });
  });
};

const unobserveElements = (
  elements: NodeListOf<Element>,
  observer: ResizeObserver
) => {
  elements.forEach((element) => {
    observer.unobserve(element);
  });
};

export function useTrackElement(
  selector: string,
  callback: (rect: BoundingRect | null) => void
) {
  useEffect(() => {
    const updateRects = debounce(() => {
      const element = document.querySelector(selector);
      if (!element) {
        callback(null);
        return;
      }

      const rect = element.getBoundingClientRect();
      const { left, right, top, bottom } = rect;
      callback({ left, right, top, bottom });
    }, 250);

    const observer = new ResizeObserver(() => {
      updateRects();
    });

    const element = document.querySelector(selector);
    if (element) {
      observer.observe(element);
    }

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          // Fixed code according to the lint_context_0 error
          Array.from(mutation.addedNodes).forEach((node) => {
            if (node instanceof HTMLElement && node.matches(selector)) {
              observeElementForResize(node, observer);
              updateRects(); // Update rects immediately for the new element and its matching children
            }
          });
        }
        if (mutation.removedNodes) {
          Array.from(mutation.removedNodes).forEach((node) => {
            if (node instanceof HTMLElement && node.matches(selector)) {
              observer.unobserve(node);
              updateRects(); // Update rects immediately for the removed element and its matching children
            }
          });
        }
      });
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    window.addEventListener("resize", updateRects);

    // Always call it once to get the initial rects
    updateRects();

    return () => {
      if (element) {
        observer.unobserve(element);
      }
      mutationObserver.disconnect();
      window.removeEventListener("resize", updateRects);
    };
  }, [callback, selector]);
}

export function useTrackNotistackElements(
  callback: (rects: BoundingRect[]) => void
) {
  const interactableSelector = ".atlas-Toast";

  useEffect(() => {
    const updateRects = () => {
      const elements = document.querySelectorAll(interactableSelector);
      const rects = getRects(elements);
      callback(rects);
    };

    const observer = new ResizeObserver(() => {
      updateRects();
    });

    const elements = document.querySelectorAll(interactableSelector);
    observeElementsForResize(elements, observer);

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          Array.from(mutation.addedNodes).forEach((node) => {
            if (node instanceof HTMLElement) {
              const matchingNodes = node.querySelectorAll(interactableSelector);
              observeElementsForResize(matchingNodes, observer);
              updateRects(); // Update rects immediately for the new element and its matching children
            }
          });
        }
        if (mutation.removedNodes) {
          Array.from(mutation.removedNodes).forEach((node) => {
            if (node instanceof HTMLElement) {
              const matchingNodes = node.querySelectorAll(interactableSelector);
              unobserveElements(matchingNodes, observer);
              updateRects(); // Update rects immediately for the removed element and its matching children
            }
          });
        }
      });
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    window.addEventListener("resize", updateRects);

    // Always call it once to get the initial rects
    updateRects();

    return () => {
      unobserveElements(elements, observer);
      mutationObserver.disconnect();
      window.removeEventListener("resize", updateRects);
    };
  }, [interactableSelector, callback]);
}

export function useTrackAtlasDialogElements(
  callback: (rects: BoundingRect[]) => void
) {
  const selector = "[data-dialog]";

  // In pre 0.0 versions of ariakit, there is only the `data-enter` attr present
  const alphaInteractableAttribute = "data-enter";

  // In post 0.0 versions of ariakit, the `data-enter` attr is always present,
  // and we have a new `data-open` attr that is present when the dialog is open
  const betaInteractableAttribute = "data-open";

  // To properly select for open dialogs across both versions, we can look for the existence of data-enter
  // and the lack of the display:none style (present when the dialog is closed on post-0.0 versions)
  const interactableAttributeSelector = `[${alphaInteractableAttribute}]:not([style*="display: none"])`;

  useEffect(() => {
    const updateRects = () => {
      requestAnimationFrame(() => {
        const elements = document.querySelectorAll(selector);
        const rects = getRects(elements, interactableAttributeSelector);
        // const v2rects = getRects(elements, v2InteractableAttributeSelector);
        callback(compact(rects));
      });
    };

    const resizeObserver = new ResizeObserver(() => {
      updateRects();
    });

    const attributeObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.target instanceof HTMLElement &&
          (mutation.attributeName === alphaInteractableAttribute ||
            mutation.attributeName === betaInteractableAttribute)
        ) {
          updateRects(); // Update rects immediately for the attribute change
        }
      });
    });

    const elements = document.querySelectorAll(selector);
    observeElementsForResize(elements, resizeObserver);
    observeElementsForAttributeMutation(elements, attributeObserver, [
      alphaInteractableAttribute,
      betaInteractableAttribute,
    ]);

    const addRemoveObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          Array.from(mutation.addedNodes).forEach((node) => {
            if (node instanceof HTMLElement) {
              const matchingNodes = node.querySelectorAll(selector);
              observeElementsForAttributeMutation(
                matchingNodes,
                attributeObserver,
                [alphaInteractableAttribute, betaInteractableAttribute]
              );

              if (node.matches(selector)) {
                attributeObserver.observe(node, {
                  attributes: true,
                  attributeFilter: [
                    alphaInteractableAttribute,
                    betaInteractableAttribute,
                  ],
                });
              }
              updateRects(); // Update rects immediately for the new element and its matching children
            }
          });
        }
      });
    });

    attributeObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    addRemoveObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    window.addEventListener("resize", updateRects);

    // Always call it once to get the initial rects
    updateRects();

    return () => {
      unobserveElements(elements, resizeObserver);
      addRemoveObserver.disconnect();
      attributeObserver.disconnect();
      window.removeEventListener("resize", updateRects);
    };
  }, [
    selector,
    callback,
    alphaInteractableAttribute,
    interactableAttributeSelector,
  ]);
}

export const useWatchFileDialogOpen = () => {
  const { incrementDialogCount, decrementDialogCount } = useDialogContext();
  const openRef = useRef(false);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "open"
        ) {
          const target = mutation.target as HTMLDialogElement;
          const isOpen = target.hasAttribute("open");
          if (isOpen && !openRef.current) {
            incrementDialogCount();
            openRef.current = true;
          } else if (!isOpen && openRef.current) {
            decrementDialogCount();
            openRef.current = false;
          }
        }
      });
    });

    const lrModal = document.querySelector("lr-modal");
    if (lrModal) {
      const dialog = lrModal.querySelector("dialog");
      if (dialog) {
        observer.observe(dialog, { attributes: true });
      }
    }

    const config = { childList: true, subtree: true };
    const domObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            if (
              node instanceof HTMLElement &&
              node.nodeName.toLowerCase() === "lr-modal"
            ) {
              const dialog = node.querySelector("dialog");
              if (dialog) {
                observer.observe(dialog, { attributes: true });
              }
            }
          });
        }
      }
    });

    domObserver.observe(document.body, config);

    return () => {
      observer.disconnect();
      domObserver.disconnect();
    };
  }, [incrementDialogCount, decrementDialogCount]);
};
