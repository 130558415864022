import { DEFAULT_ROUTE_MATCHING } from "generated/default-route-matcher";
import { find } from "lodash";
import { match, MatchFunction } from "path-to-regexp";
import { trackProperties } from "shared/analytics";
import { formatEntity } from "shared/constants/entities";
import urljoin from "url-join";

export type Route = {
  matcher: MatchFunction;
  /** Name of page -- used for analytics */
  name: string;
  /** Title of page -- used for display */
  title?: string;
  disableTrackPage?: boolean;
  customerSuccess?: boolean;
  hideBetaLabel?: boolean;
};

const RouteMatchingOverrides: Route[] = [
  {
    matcher: match("/_error"),
    name: "Server Error",
    title: "Server Error",
  },
  {
    matcher: match("/404"),
    name: "Not Found",
    title: "Not Found",
  },
  {
    matcher: match("/500"),
    name: "Server Error",
    title: "Server Error",
  },
  {
    matcher: match("/close"),
    name: "Close",
  },
  {
    matcher: match("/invalid-permissions"),
    name: "Invalid Permissions",
  },
  {
    matcher: match("/new-user"),
    name: "New User",
  },
  {
    matcher: match("/cookies-required"),
    name: "Cookies Required",
  },
  {
    matcher: match("/extension/modals"),
    name: "Guide Modals",
    disableTrackPage: true,
  },
  {
    matcher: match("/extension/guide/:guideId"),
    name: "Guide Progress",
  },
  {
    matcher: match("/extension/guide/:guideId/interviews"),
    name: "Extension Interviews",
  },
  {
    matcher: match("/extension/guide/:guideId/interviews/:interviewId"),
    name: "Extension Interview Details",
  },
  {
    matcher: match("/extension/action/:guideId/interviews/:interviewId"),
    name: "Extension Interview Details",
  },
  {
    matcher: match("/login(.*)"),
    name: "Login",
  },
  {
    matcher: match("/jobs/:jobId/stages"),
    name: "Job Guide Settings Hiring Plan",
    title: formatEntity("interview plan", { capitalize: true }),
  },
  {
    matcher: match("/jobs/:jobId/interviews"),
    name: "Job Interviews",
  },
  {
    matcher: match("/jobs/:jobId/setup"),
    name: "Job Setup",
  },
  {
    matcher: match("/jobs/:jobId"),
    name: "Job",
  },
  {
    matcher: match("/jobs"),
    name: "Jobs",
  },
  {
    matcher: match("/templates"),
    name: "Templates",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/templates/messages"),
    name: "Message Templates",
    title: `${formatEntity("message", { capitalize: true })} Templates`,
  },
  {
    matcher: match("/templates/guides"),
    name: "Guide Templates",
    title: formatEntity("guide template", { capitalize: true, plural: true }),
  },
  {
    matcher: match("/templates/guides/:guideTemplateId/stages"),
    name: "Guide Template Hiring Plan",
    title: `${formatEntity("guide template", {
      capitalize: true,
    })} Hiring Plan`,
  },
  {
    matcher: match("/templates/interviews"),
    name: "Interview Templates",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/settings"),
    name: "Settings",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/settings/users"),
    name: "Users",
  },
  {
    matcher: match("/settings/company"),
    name: "Company Profile",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/settings/integrations"),
    name: "Company Integrations",
  },
  {
    matcher: match("/settings/configuration"),
    name: "Company Configuration",
  },
  {
    matcher: match("/settings/interviews"),
    name: "Interview Templates",
  },
  {
    matcher: match("/settings/tags"),
    name: "Organization tags",
  },
  {
    matcher: match("/account/profile"),
    name: "Account Profile",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/account/settings"),
    name: "Account Settings",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/"),
    name: "Home",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId"),
    name: "Home Page - Candidate Guide",
    title: `Your ${formatEntity("guide")} - Home`,
    ...trackProperties.isCustomerSuccess,
    hideBetaLabel: true,
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/messages"),
    name: "Conversations",
    title: `Your ${formatEntity("guide")} - ${formatEntity("message", {
      capitalize: true,
      plural: true,
    })}`,
    ...trackProperties.isCustomerSuccess,
    hideBetaLabel: true,
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/message/:messageId"),
    name: "Conversation",
    title: `Your ${formatEntity("guide")} - ${formatEntity("message", {
      capitalize: true,
    })}`,
    ...trackProperties.isCustomerSuccess,
    hideBetaLabel: true,
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/interviews"),
    name: "Interviews Page - Candidate Guide",
    title: `Your ${formatEntity("guide")} - ${formatEntity("interview", {
      capitalize: true,
      plural: true,
    })}`,
    ...trackProperties.isCustomerSuccess,
    hideBetaLabel: true,
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/interviews/past"),
    name: "Past Interviews Page - Candidate Guide",
    title: `Your ${formatEntity("guide")} - Past ${formatEntity("interview", {
      plural: true,
    })}`,
    hideBetaLabel: true,
  },
  {
    matcher: match("/extension"),
    name: "Guide Extension",
    title: "Guide Extension Sidepanel",
    disableTrackPage: true,
  },
  {
    matcher: match("/co2"),
    name: "Carbon Dioxide",
    title: "Carbon Dioxide",
    disableTrackPage: true,
  },
  {
    matcher: match("/signup"),
    name: "Sign Up",
    title: "Sign Up",
  },
  {
    matcher: match("/reports/surveys"),
    name: "Surveys Report",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/reports/training/events"),
    name: "Training Events Report",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/reports/training/load"),
    name: "Training Load Report",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/reports/interviewers"),
    name: "Interviewers Report",
    ...trackProperties.isCustomerSuccess,
  },
  {
    matcher: match("/reports/recruiting-team"),
    name: "Scheduling Report",
    ...trackProperties.isCustomerSuccess,
  },
];

const routeForPathname = (
  pathnameWithQueryParams: string
): Route | undefined => {
  const url = new URL(pathnameWithQueryParams, "https://app3.guide.co");
  const override = find(
    RouteMatchingOverrides,
    ({ matcher }) => !!matcher(url.pathname)
  );

  if (override) {
    return override;
  }

  const defaultRoute = find(
    DEFAULT_ROUTE_MATCHING,
    ({ matcher }) => !!matcher(url.pathname)
  );

  if (defaultRoute) {
    return defaultRoute;
  }

  return undefined;
};

export const appendRelativePath = (
  routerPathWithQueryParams: string,
  relativePath: string,
  qs: string | undefined = ""
): string => {
  const url = new URL(
    routerPathWithQueryParams,
    process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? process.env.STORYBOOK_APP_ROOT
  );
  return urljoin(url.pathname, relativePath, qs);
};

export default routeForPathname;
