/**
 * A simple representation of an elements display so that we can recreate in various ways. We do not use
 * raw elements because we ish to pass these between iframes
 * and raw elements are not serializable.
 */
export type SerializedElement = {
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  style: {
    borderTopLeftRadius?: string;
    borderBottomLeftRadius?: string;
    borderTopRightRadius?: string;
    borderBottomRightRadius?: string;
  };
};

/**
 * Takes in an element and converts it to a simple representation containing only the important visual information we care about.
 *
 * @param el the element on the page
 * @returns
 */
export function serializeElement(
  el: Element | null | undefined
): SerializedElement | null {
  if (!el) {
    return null;
  }

  const rect = el.getBoundingClientRect();
  const style = window.getComputedStyle(el);

  return {
    rect: {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    },
    // We only send the properties that we care about as 1. Raw CSSStyleDeclaration is not serializable and 2.
    // It's a big object. We may wish to be more proactive
    // and send more styling results by default (such as visibility)
    style: {
      borderTopLeftRadius: style.borderTopLeftRadius,
      borderTopRightRadius: style.borderTopRightRadius,
      borderBottomLeftRadius: style.borderBottomLeftRadius,
      borderBottomRightRadius: style.borderBottomRightRadius,
    },
  };
}
