import { useDialogContext } from "@resource/atlas/dialog/DialogContext";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClose } from "@resource/atlas/icons";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import semver from "semver";
import inIframe from "utils/in-iframe";

import { EXTENSION_DIMS } from "./dimensions";
import {
  PopoverWidthVariants,
  useExtensionController,
} from "./ExtensionControllerProvider";
import { FloatingCloseButton } from "./FloatingCloseButton";
import {
  useTrackAtlasDialogElements,
  useTrackElement,
  useTrackNotistackElements,
  useWatchFileDialogOpen,
} from "./hooks/useTrackDialogElements";
// Uncomment to test fake frame css in dev
// import style from "./ExtensionDevLayout.module.sass";

function FakeParentFrame({ children }: { children: ReactNode }) {
  const { setPanelOpenState } = useExtensionController();

  useEffect(() => {
    // For dev testing
    setPanelOpenState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
    // TODO: Uncomment to test fake frame css in dev
    // className={clsx(
    //   style.fakeFrame,
    //   panelOpen ? style.expanded : style.collapsing
    // )}
    >
      {children}
    </div>
  );
}

const isMouseEventInRect = (
  mouseEvent: MouseEvent,
  rect: BoundingRect | null
) => {
  return (
    rect &&
    mouseEvent.clientX >= rect.left &&
    mouseEvent.clientX <= rect.right &&
    mouseEvent.clientY >= rect.top &&
    mouseEvent.clientY <= rect.bottom
  );
};

type BoundingRect = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export function InnerFrame({ children }: { children: ReactNode }) {
  const [isMouseOverInteractableElement, setIsMouseOverInteratableElement] =
    useState(false);
  const { isAnyDialogOpen } = useDialogContext();
  const {
    panelOpenState: panelIsOpen,
    extensionVersion,
    setPanelOpenState,
    sendMessageToParent,
    popoverWidthVariant,
  } = useExtensionController();

  const popoverRect = useRef<BoundingRect | null>(null);
  const dialogRects = useRef<BoundingRect[]>([]);
  const notistackRects = useRef<BoundingRect[]>([]);
  const popoverCloseRect = useRef<BoundingRect | null>(null);

  const popoverWidth = PopoverWidthVariants[popoverWidthVariant];

  const wrapStyles: React.CSSProperties = {
    position: "absolute",
    right: 0,
    top: 0,
    overflow: "hidden",
    width: "100vw",
    height: "100vh",
    zIndex: 1,
  };

  const popoverCloseButtonStyles: React.CSSProperties = {
    ...(panelIsOpen
      ? {
          opacity: isAnyDialogOpen ? 0 : 1,
          transition: `opacity ${
            EXTENSION_DIMS.popoverCloseButton.animation.opacityTransitionMs
          }ms ease ${
            EXTENSION_DIMS.panel.animations.heightTransitionMs -
            EXTENSION_DIMS.popoverCloseButton.animation.opacityTransitionMs / 2
          }ms`,
        }
      : {
          opacity: 0,
          transition: `opacity ${EXTENSION_DIMS.popoverCloseButton.animation.opacityTransitionMs}ms ease 0s`,
        }),
    width: EXTENSION_DIMS.popoverCloseButton.width,
    height: EXTENSION_DIMS.popoverCloseButton.height,
    right:
      popoverWidth +
      EXTENSION_DIMS.panel.padding.right +
      EXTENSION_DIMS.panel.padding.left,
    top:
      EXTENSION_DIMS.panel.padding.top +
      EXTENSION_DIMS.popoverCloseButton.padding.top,
    boxShadow:
      "0px 8px 8px rgba(35, 31, 50, 0.1), 0px 0px 8px rgba(35, 31, 50, 0.1), 0px 0px 0px 1px rgba(35, 31, 50, 0.05)",
  };

  const popoverStyles: React.CSSProperties = {
    ...(panelIsOpen
      ? {
          opacity: 1,
          width: `${popoverWidth || EXTENSION_DIMS.panel.width}px`,
          height: `calc(100% - ${
            EXTENSION_DIMS.panel.padding.bottom +
            EXTENSION_DIMS.panel.padding.top +
            EXTENSION_DIMS.floatingButton.height +
            EXTENSION_DIMS.floatingButton.padding.bottom
          }px)`,
        }
      : {
          opacity: 0,
          width: "0px",
          height: "0px",
          transform: "scale(0)",
        }),
    right: `${EXTENSION_DIMS.panel.padding.right}px`,
    bottom: `${
      EXTENSION_DIMS.floatingButton.padding.bottom +
      EXTENSION_DIMS.floatingButton.height +
      EXTENSION_DIMS.panel.padding.bottom
    }px`,
    transition: `width ${EXTENSION_DIMS.panel.animations.widthTransitionMs}ms cubic-bezier(0, 1.2, 1, 1) 0s, 
      height ${EXTENSION_DIMS.panel.animations.heightTransitionMs}ms ease 0s, 
      transform ${EXTENSION_DIMS.panel.animations.transformTransitionMs}ms cubic-bezier(0, 1.2, 1, 1) 0s, 
      opacity ${EXTENSION_DIMS.panel.animations.opacityTransitionMs}ms ease-out 0s`,
    transformOrigin: "right bottom",
    overflow: "hidden",
    boxShadow: `rgba(0, 0, 0, 0.16) 0px 0px ${EXTENSION_DIMS.panel.padding.top}px`,
  };

  const sendInteractableBounds = useCallback(() => {
    const interactableBounds = [
      ...(popoverRect.current ? [popoverRect.current] : []),
      ...(popoverCloseRect.current ? [popoverCloseRect.current] : []),
      ...dialogRects.current,
      ...notistackRects.current,
    ];
    console.debug("[Guide] interactableBounds: ", interactableBounds);
    sendMessageToParent({
      command: "update-interactable-bounding-rects",
      value: interactableBounds,
    });
  }, [sendMessageToParent]);

  useEffect(() => {
    if (isAnyDialogOpen) {
      sendMessageToParent({
        command: "update-sidepanel-pointer-events",
        value: "auto",
      });
      return;
    }

    sendMessageToParent({
      command: "update-sidepanel-pointer-events",
      value: isMouseOverInteractableElement ? "auto" : "none",
    });

    sendInteractableBounds();
  }, [
    isMouseOverInteractableElement,
    isAnyDialogOpen,
    panelIsOpen,
    sendMessageToParent,
    sendInteractableBounds,
  ]);

  // Then you can use this function in your code like this:

  const getIsMouseOverInteractableElement = useCallback(
    (mouseEvent: MouseEvent) => {
      if (isMouseEventInRect(mouseEvent, popoverRect.current)) {
        return true;
      }

      if (isMouseEventInRect(mouseEvent, popoverCloseRect.current)) {
        return true;
      }

      for (const rect of dialogRects.current) {
        if (isMouseEventInRect(mouseEvent, rect)) {
          return true;
        }
      }

      for (const rect of notistackRects.current) {
        if (isMouseEventInRect(mouseEvent, rect)) {
          return true;
        }
      }

      return false;
    },
    [popoverRect, popoverCloseRect, dialogRects, notistackRects]
  );

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const isOver = getIsMouseOverInteractableElement(event);
      setIsMouseOverInteratableElement(isOver);
    };

    window.document.addEventListener("mousemove", handler);
    return () => window.document.removeEventListener("mousemove", handler);
  }, [getIsMouseOverInteractableElement]);

  useTrackElement("#guide-extension-popover", (rect) => {
    popoverRect.current = rect;
    sendInteractableBounds();
  });

  useTrackElement("#guide-extension-popover-close", (rect) => {
    popoverCloseRect.current = rect;
    sendInteractableBounds();
  });

  useTrackNotistackElements((rects) => {
    notistackRects.current = rects;
    sendInteractableBounds();
  });

  useTrackAtlasDialogElements((rects) => {
    dialogRects.current = rects;
    sendInteractableBounds();
  });

  useWatchFileDialogOpen();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          setPanelOpenState(false);
        }}
        className={clsx(
          "overflow-hidden transition-colors duration-120",
          extensionVersion &&
            semver.eq(extensionVersion, "3.1.14") &&
            panelIsOpen
            ? "bg-dark-blue-500/20"
            : "bg-transparent"
        )}
        style={wrapStyles}
      >
        <div
          id="guide-extension-popover"
          className="rounded-2xl overflow-hidden absolute bg-white"
          style={popoverStyles}
        >
          <StopPropagation>{children}</StopPropagation>
        </div>
        <div
          id="guide-extension-popover-close"
          className="transition-opacity fixed rounded-full bg-dark-blue-500 cursor-pointer flex items-center justify-center"
          style={popoverCloseButtonStyles}
        >
          <Icon content={atlasClose} className="text-white w-5 h-5" />
        </div>
      </div>
    </>
  );
}

export function ExtensionLayout({ children }: { children?: ReactNode }) {
  const {
    frameVariant,
    setPanelOpenState,
    panelOpenState: panelIsOpen,
    sendMessageToParent,
    isFakeExtensionFrame,
    isModalFrame,
  } = useExtensionController();

  const isSidepaneIframe = inIframe() && !isModalFrame;

  useEffect(() => {
    if (isSidepaneIframe) {
      console.debug(
        `[Guide] ExtensionLayout: panelIsOpen: ${panelIsOpen}, frameVariant: ${frameVariant}`
      );
    }
  }, [panelIsOpen, frameVariant, isSidepaneIframe]);

  useEffect(() => {
    if (isSidepaneIframe) {
      sendMessageToParent({
        command: "sidepanel-iframe-initialized",
      });
    }
  }, [isSidepaneIframe, sendMessageToParent]);

  useEffect(() => {
    // Update the extension every time the frame variant changes via feature flag
    if (isSidepaneIframe && typeof frameVariant !== "undefined") {
      sendMessageToParent({
        command: "set-frame-variant",
        value: frameVariant,
      });
    }
  }, [frameVariant, isSidepaneIframe, sendMessageToParent]);

  if (isSidepaneIframe) {
    return frameVariant === "fullscreen" ? (
      <InnerFrame>{children}</InnerFrame>
    ) : (
      <div className="w-full h-screen">{children}</div>
    );
  }

  if (isModalFrame) {
    return <>{children}</>;
  }

  return isFakeExtensionFrame ? (
    <>
      <FakeParentFrame>
        <InnerFrame>{children}</InnerFrame>
      </FakeParentFrame>
      <FloatingCloseButton onClick={() => setPanelOpenState(!panelIsOpen)} />
    </>
  ) : (
    <>{children}</>
  );
}
