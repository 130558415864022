import { SerializedAutoLinkNode, SerializedLinkNode } from "@lexical/link";
import { SerializedListItemNode, SerializedListNode } from "@lexical/list";
import { SerializedHorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { SerializedHeadingNode, SerializedQuoteNode } from "@lexical/rich-text";
import { SerializedLexicalNode, SerializedTextNode } from "lexical";

// intentionally internal import to avoid circular dependency issues
import {
  createNodeRenderer,
  createNodeRenderers,
} from "../../../../renderer/__lib/create-node-renderer";
import { Txt } from "../../../../renderer/__lib/create-text-renderer";

export const renderTextHeading = createNodeRenderer<SerializedHeadingNode>(
  ({ tag }, children) => (
    <Txt.Block>
      {tag === "h1" ? "#" : "##"} {children}
    </Txt.Block>
  )
);

export const renderTextList = createNodeRenderer<SerializedListNode>(
  (_, children, { list: { nesting } }) => {
    const Tag = nesting === 0 ? Txt.Block : Txt.Text;
    return <Tag>{children}</Tag>;
  }
);

// test code: https://pastebin.com/MdWjeCxd
function getAlphabeticalBullet(index: number) {
  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
  const size = alphabet.length;
  const n = (index + 1).toString(size);
  return n
    .split("")
    .map((d, i) => {
      const int = parseInt(d, size);
      const isLeftmost = i === 0;
      return alphabet[isLeftmost ? int - 1 : int];
    })
    .join("");
}

function getOlBullet(indentLevel: number, index: number) {
  return `${indentLevel === 1 ? getAlphabeticalBullet(index) : index + 1}.`;
}

export const renderTextListItem = createNodeRenderer<SerializedListItemNode>(
  (
    _,
    children,
    {
      childIndex,
      list: { type, start = 1, isListNestedItem, nesting, indexDrift },
    }
  ) => {
    if (!type) throw new Error("List item not in list");
    if (childIndex == null) throw new Error("List item can't be root");
    if (nesting == null) throw new Error("Missing list nesting context");
    if (indexDrift == null) throw new Error("Missing index drift context");

    if (isListNestedItem) return <Txt.Text>{children}</Txt.Text>;

    const leftPadding = " ".repeat((nesting ?? 0) * 2);
    const bullet =
      type === "ul"
        ? "•"
        : getOlBullet(nesting, childIndex - indexDrift + start);

    return (
      <Txt.Line>
        {leftPadding}
        {bullet} {children}
      </Txt.Line>
    );
  }
);

function hasLinkLabel(url: string, children: SerializedLexicalNode[]) {
  if (children.length === 0) {
    return false; // cannot have a label if no children
  }

  return (
    children.length > 1 || // more than one child
    children[0].type !== "text" || // child is not a text node
    // text is not the same as url
    (children[0] as SerializedTextNode).text !== url
  );
}

export const renderTextLink = createNodeRenderer<
  SerializedLinkNode | SerializedAutoLinkNode
>(({ url, children: serializedChildren }, children) =>
  hasLinkLabel(url, serializedChildren) ? (
    <Txt.Text>
      [{children}]({url})
    </Txt.Text>
  ) : (
    <Txt.Text>{`<${url}>`}</Txt.Text>
  )
);

export const renderTextHorizontalRule =
  createNodeRenderer<SerializedHorizontalRuleNode>(() => (
    <Txt.Block>---</Txt.Block>
  ));

export const renderTextQuote = createNodeRenderer<SerializedQuoteNode>(
  (_, children) => <Txt.Text>{children}</Txt.Text>
);

/** Text renderers for nodes that are part of the rich text functionality of content editor. */
export const richTextTextNodeRenderers = createNodeRenderers({
  heading: renderTextHeading,
  link: renderTextLink,
  autolink: renderTextLink,
  list: renderTextList,
  listitem: renderTextListItem,
  horizontalrule: renderTextHorizontalRule,
  quote: renderTextQuote,
});
