import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import Badge from "../badge/Badge";

export interface TabProps extends Omit<Ariakit.TabProps, "size"> {
  /** The size of the tab */
  size?: "default" | "large" | "small";
  /** Whether the tab is disabled */
  isDisabled?: boolean;
  /** Optional count to display */
  count?: number;
  /** The visual style of the tab */
  variant?: "pill" | "underlined";
}

export interface TabListProps extends Ariakit.TabListProps {
  /** The selected tab ID */
  selectedId?: string;
  /** Callback when selected tab changes */
  onSelectedChange?: (id: string) => void;
}

export interface TabPanelProps extends Ariakit.TabPanelProps {
  /** Custom className for the panel */
  className?: string;
}

export interface TabRootProps {
  /** The selected tab ID */
  selectedId?: string;
  /** Callback when selected tab changes */
  onSelectedChange?: (id: string) => void;
  /** The children to render */
  children: React.ReactNode;
  /** Custom className for the root element */
  className?: string;
}

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  (props, ref) => {
    const {
      children,
      size = "default",
      variant = "underlined",
      isDisabled,
      count,
      className,
      ...rest
    } = props;

    return (
      <Ariakit.Tab
        ref={ref}
        disabled={isDisabled}
        accessibleWhenDisabled={false}
        {...rest}
        className={clsx(
          "group",
          "flex items-center gap-1",
          variant === "pill" && [
            "rounded-full px-3 py-1",
            "text-subtle",
            "hover:bg-light-gray-200",
            "aria-selected:bg-purple-50 aria-selected:text-purple-500",
            "active:bg-purple-50 active:text-purple-500",
            "data-[active-item]:bg-purple-50 data-[active-item]:text-purple-500",
            "active:hover:bg-purple-100 active:hover:text-purple-600",
          ],
          variant === "underlined" && [
            "px-4 py-2",
            "text-subtle",
            "border-b-2 border-transparent",
            "hover:text-dark hover:border-gray-border",
            "active:cursor-default aria-selected:cursor-default data-[active-item]:cursor-default",
            "aria-selected:border-purple-500 aria-selected:text-purple-500",
            "active:border-purple-500 active:text-purple-500",
            "data-[active-item]:border-purple-500 data-[active-item]:text-purple-500",
          ],
          // Disabled state
          isDisabled && "opacity-50 cursor-not-allowed",
          // Size variants
          {
            "px-4 py-1.5 text-body-md": size === "large",
            "px-3 py-1 text-body-sm": size === "default",
            "px-2 py-0.5 text-body-xs": size === "small",
          },
          className
        )}
      >
        {children}
        {typeof count !== "undefined" && (
          <Badge variant="purple" size="small">
            {count}
          </Badge>
        )}
      </Ariakit.Tab>
    );
  }
);

export const TabList = React.forwardRef<HTMLDivElement, TabListProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;

    return (
      <Ariakit.TabList
        ref={ref}
        {...rest}
        className={clsx("flex gap-1 p-2", className)}
      >
        {children}
      </Ariakit.TabList>
    );
  }
);

export const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;

    return (
      <Ariakit.TabPanel
        ref={ref}
        {...rest}
        className={clsx("outline-none", className)}
      >
        {children}
      </Ariakit.TabPanel>
    );
  }
);

export const TabRoot = React.forwardRef<HTMLDivElement, TabRootProps>(
  (props, ref) => {
    const { selectedId, onSelectedChange, children, className } = props;

    return (
      <Ariakit.TabProvider
        selectedId={selectedId}
        setSelectedId={(id) => id && onSelectedChange?.(id)}
      >
        <div ref={ref} className={className}>
          {children}
        </div>
      </Ariakit.TabProvider>
    );
  }
);

Tab.displayName = "Tab";
TabList.displayName = "TabList";
TabPanel.displayName = "TabPanel";
TabRoot.displayName = "TabRoot";
