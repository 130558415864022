import { SerializedMentionNode } from "@resource/atlas/content-editor/mentions";
import { MentionValue } from "@resource/atlas/content-editor/mentions/__lib/types";
import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";

function EmailMention({ value }: { value: MentionValue }) {
  return (
    <span className="inline-block select-none cursor-pointer bg-purple-50 text-purple-500">
      @{value.name}
    </span>
  );
}

export const createMentionEmailRenderer = () =>
  createNodeRenderer<SerializedMentionNode>(({ value }) => {
    return <EmailMention value={value} />;
  });
