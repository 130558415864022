import { Avatar } from "@resource/atlas/avatar/Avatar";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import React from "react";

import { getNotificationDisplay } from "./display";
import { NotificationType } from "./utils/types";

export interface NotificationDisplayProps {
  notification: NotificationType;
  onClick: () => void;
}

export function NotificationDisplay({
  notification,
  onClick,
}: NotificationDisplayProps) {
  const { read, actor, href } = notification;
  const router = useRouter();
  const DisplayComponent = getNotificationDisplay(notification);
  const actorName = actor?.name || "Unknown User";

  const getRelativeTime = (date: string) => {
    const minutesSinceCreation = DateTime.fromISO(date).diff(
      DateTime.now(),
      "minutes"
    ).minutes;

    if (minutesSinceCreation > -1) {
      return "just now";
    }
    return DateTime.fromISO(date).toRelative();
  };

  const handleNavigate = () => {
    if (href) {
      const { pathname: path, search: queryString } = new URL(
        href,
        window.location.origin
      );
      const queryParams = queryString
        ? Object.fromEntries(new URLSearchParams(queryString))
        : {};

      if (router.pathname === path) {
        // If same path, just replace query params while preserving existing ones
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              ...queryParams,
            },
          },
          undefined,
          { shallow: true }
        );
      } else {
        // If different path, push full href
        router.push(href);
      }
      onClick();
    }
  };

  return (
    <div
      className={clsx(
        "w-full flex items-start gap-3 p-3 rounded-lg relative",
        href ? "hover:bg-light-gray-200 cursor-pointer" : "cursor-default"
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleNavigate();
        }
      }}
      onClick={handleNavigate}
    >
      <Avatar size="large" image={actor?.avatarUrl} name={actorName} />
      <div className="space-y-2 grow">
        <div className="flex items-center gap-2">
          <DisplayComponent notification={notification} className="grow" />
          {!read && (
            <div className="h-2 w-2 bg-purple-500 rounded-full shrink-0" />
          )}
        </div>
        <p className="text-body-sm text-subtle">
          {getRelativeTime(notification.createdAt)}
        </p>
      </div>
    </div>
  );
}
