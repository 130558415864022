import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasClose } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import clsx from "clsx";
import React, { ComponentPropsWithoutRef, ReactNode } from "react";

import { TOUR_Z_INDICES } from "../TourRenderer";

export type MessageBoxBaseProps = {
  image?: string;
  hideDismiss?: boolean;
  onDismiss: () => unknown;
  onCTAClick: () => unknown;
  ctaText?: string;
  hideCTA?: boolean;
  learnMoreUrl?: string;
};

type RawMessageBoxProps = ComponentPropsWithoutRef<"div"> &
  MessageBoxBaseProps & {
    arrow?: ReactNode;
  };

export function RawMessageBox({
  arrow,
  image,
  onCTAClick,
  hideDismiss = false,
  onDismiss,
  ctaText,
  hideCTA = false,
  learnMoreUrl,
  children,
  className,
  ...props
}: RawMessageBoxProps) {
  return (
    <div
      className={clsx(
        "w-[18.75rem] rounded-md bg-white drop-shadow-xl text-body-md relative .tours-components",
        className
      )}
      style={{
        ...props.style,
        zIndex: TOUR_Z_INDICES.CONTENT,
      }}
      {...props}
    >
      {arrow}

      {!hideDismiss && (
        <Button
          icon={atlasClose}
          className="absolute top-1 right-1 z-10"
          size="xs"
          onClick={onDismiss}
        />
      )}

      {image && (
        <div
          className="rounded-t-md bg-cover w-full h-40 p-1 flex items-start justify-end relative z-1 border-b border-gray-border"
          style={{ backgroundImage: `url('${image}')` }}
        />
      )}

      <div className="px-4 py-6">{children}</div>
      <div className="px-4 pb-4 flex justify-between items-center">
        {learnMoreUrl && (
          <Link
            href={learnMoreUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-body-md"
            isExternal
            variant="default"
          >
            Learn more
          </Link>
        )}
        <div className="grow" />
        {!hideCTA && (
          <ButtonGroup>
            <Button variant="primary" onClick={onCTAClick}>
              {ctaText || "Next"}
            </Button>
          </ButtonGroup>
        )}
      </div>
    </div>
  );
}
