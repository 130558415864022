import * as Ariakit from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Menu } from "@resource/atlas/menu-v2";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { signOut } from "next-auth/react";
import { useCallback } from "react";

import { useAppNavAccountMenuItems } from "./hooks/useAppNavAccountMenuItems";

type AppNavAccountMenuProps = {
  target?: "self" | "_blank";
  hideDashboard?: boolean;
};

function AppNavAccountMenu({ target, hideDashboard }: AppNavAccountMenuProps) {
  const store = Ariakit.useMenuStore({
    placement: "right-end",
  });
  const state = Ariakit.useStoreState(store);
  const { onLogout, user } = useAuthContext();
  const logEvent = useLogEvent({ component: "AccountMenu" });

  const logout = useCallback(async () => {
    logEvent("Sidebar Sign Out Clicked");
    await onLogout();
    signOut();
  }, [onLogout, logEvent]);

  const menuItems = useAppNavAccountMenuItems({
    target,
    hideDashboard,
    logout,
  });

  return (
    <Menu.Root store={store}>
      <Ariakit.MenuButton className="w-full">
        <ListItem isInteractive isSelected={state.open}>
          <div className="flex items-center gap-2">
            <Avatar
              as="button"
              size="xs"
              name={user?.fullName}
              image={user?.imageUrl}
              disableTooltip
              aria-label="Open account menu"
            />
            {user?.fullName ?? "Your account"}
          </div>
        </ListItem>
      </Ariakit.MenuButton>
      <Menu.Menu>
        {/* Header */}
        <ListItem
          className="overflow-visible"
          size="compact"
          renderContent={() => (
            <div className="space-y-1">
              <p className="text-body-md-heavy text-dark truncate">
                {user?.fullName ?? ""}
              </p>
              <p className="text-body-sm text-subtle truncate">
                {user?.primaryEmail ?? ""}
              </p>
            </div>
          )}
        />
        <Menu.Separator key="separator-account-details" />
        {menuItems}
      </Menu.Menu>
    </Menu.Root>
  );
}

export default AppNavAccountMenu;
