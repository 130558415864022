import clsx from "clsx";
import React, { ComponentPropsWithoutRef } from "react";

import { TOUR_Z_INDICES } from "../TourRenderer";
import { MessageBoxBaseProps, RawMessageBox } from "./RawMessageBox";

type NarratorProps = ComponentPropsWithoutRef<"div"> & MessageBoxBaseProps;

export function Narrator({
  children,
  className,
  style,
  ...messageBoxProps
}: NarratorProps) {
  return (
    <div
      className={clsx("fixed bottom-0 left-0 p-4", className)}
      style={{ zIndex: TOUR_Z_INDICES.CONTENT, ...style }}
    >
      <RawMessageBox
        {...messageBoxProps}
        className={clsx("mx-3 relative mb-6", className)}
      >
        {children}
      </RawMessageBox>
    </div>
  );
}
